import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CircularProgress, Grid, Switch } from "@mui/material";
import UserAvatar from "assets/images/userAvatar.png";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CollapsibleDataTable from 'examples/Tables/CollapsibleDataTable';
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PartyModal from 'examples/Modals/PartyModal';
import Toaster, { showToastMessage } from "examples/Toaster";
import { useFormik } from 'formik';
import { CreatePartyApi, GetPartyApi, UpdatePartyApi, deletePartyApi } from "helper/api";
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import ConfirmationModal from 'examples/Modals/ConfirmationModal';

const validSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact_number: Yup.string().matches(/^[0-9]\d{9}$/, "Invalid Contact Number").required("Contact Number is required"),
    contact_person_name: Yup.string().required("Name is required"),
    amount: Yup.number('Only allow number').integer().required("Amount is required"),
    delivery_locations: Yup.array().of(Yup.string().required("Delivery location is required"))
});

const Party = () => {
    const [openModal, setOpenModal] = useState(false);
    const [partyDataRow, setPartyDataRow] = useState([]);
    const [partyDataColumn, setPartyDataColumn] = useState([]);
    const [isShowLoading, setIsShowLoading] = useState(true);
    const [modalTitle, setModalTitle] = useState("Create Party")
    const [editPartyData, setEditPartyData] = useState({})
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [partyId, setPartyId] = useState("")
    const [type, setType] = useState("")

    // For Create Party..
    const createPartyData = async (values) => {
        const createParty = await CreatePartyApi(values)
        if (createParty.status === 200) {
            showToastMessage(createParty.message, 'success')
        } else {
            showToastMessage(createParty.message, 'error')
        }
    }

    //For Update User..
    const updatePartyData = async (values) => {
        const updateParty = await UpdatePartyApi(values)
        if (updateParty.status === 200) {
            showToastMessage(updateParty.message, 'success')
            getPartyData()
        } else {
            showToastMessage(updateParty.message, 'error')
        }
    }

    // For Delete  Party..
    const deletePartyData = async () => {
        await deletePartyApi(partyId)
        getPartyData();
        setOpenDeleteModal(false);
        showToastMessage("Delete Party Successfully", 'success')
    }

    //For Update Party Activity..
    const partyActiveHandler = () => {
        const payload = {
            party_id: editPartyData._id,
            is_active: !editPartyData.is_active,
        }
        updatePartyData(payload)
        setOpenDeleteModal(false)
    }

    const clickHandlerClose = () => {
        formik.resetForm()
        setOpenModal(false);
        setOpenDeleteModal(false);
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            contact_number: '',
            type: "+",
            amount: "",
            contact_person_name: '',
            delivery_locations: [""]
        },
        onSubmit: (values, { resetForm }) => {
            let finalAmount = Number(`${values.type}${values.amount}`)
            if (modalTitle === "Edit Party") {
                let payload = {
                    party_id: editPartyData._id,
                    name: values.name,
                    contact_number: values.contact_number,
                    contact_person_name: values.contact_person_name,
                    opening_balance: values.type === "-" ? finalAmount : values.amount,
                    delivery_locations: values.delivery_locations
                }
                updatePartyData(payload)
            } else {
                let payload = {
                    name: values.name,
                    contact_number: values.contact_number,
                    contact_person_name: values.contact_person_name,
                    opening_balance: values.type === "-" ? finalAmount : values.amount,
                    delivery_locations: values.delivery_locations
                }
                createPartyData(payload)
            }
            resetForm()
            clickHandlerClose()
            getPartyData()
        },
        validationSchema: validSchema
    })

    const clickHandlerOpen = () => {
        setOpenModal(true);
        setModalTitle("Create Party")
    }

    const editPartyHandler = (values) => {
        setOpenModal(true);
        setModalTitle("Edit Party")
        setEditPartyData(values)
        formik.setValues({
            name: values.name,
            contact_number: values.contact_number,
            contact_person_name: values.contact_person_name,
            type: String(values.opening_balance).charAt(0) === "-" ? "-" : "+",
            amount: String(values.opening_balance).charAt(0) === "-" ? String(values.opening_balance).slice(1) : values.opening_balance,
            delivery_locations: [...values.delivery_locations]
        })
    }

    //For Get All Party..
    const getPartyData = async () => {
        const getParty = await GetPartyApi(pageNumber)
        setTotalPage(getParty.totalPage)
        const Name = ({ image, name }) => (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
                <MDAvatar src={image} name={name} size="sm" />
                <MDBox ml={2} lineHeight={1}>
                    <MDTypography display="block" variant="button" fontWeight="medium">
                        {name}
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
        const column = [
            { Header: "name", accessor: "name", align: "center" },
            { Header: "contact number", accessor: "contact_number", align: "center" },
            { Header: "contact person name", accessor: "contact_person_name", align: "center" },
            { Header: "Opening Balance", accessor: "opening_balance", align: "center" },
            { Header: "status", accessor: "status", align: "center" },
            { Header: "action", accessor: "action", align: "center" },
            { Header: "", accessor: "", align: "right" },
        ]
        const row = getParty.data
            ?
            getParty.data.map((val) => {
                return {
                    name: <Name image={UserAvatar} name={val.name} val={val} />,
                    contact_number: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {val.contact_number}
                        </MDTypography>
                    ),
                    contact_person_name: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {val.contact_person_name}
                        </MDTypography>
                    ),
                    opening_balance: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 2,
                            }).format(val.opening_balance)}
                        </MDTypography>
                    ),
                    status: (
                        <MDBox ml={-1}>
                            <Switch checked={val.is_active} onChange={() => {
                                setOpenDeleteModal(true)
                                setType("updateParty")
                                setEditPartyData(val)
                            }} />
                        </MDBox>
                    ),
                    delivery_locations: val.delivery_locations,
                    action: (
                        <MDTypography component="a" variant="caption" color="text" fontWeight="medium" display="flex" alignItems="center" justifyContent="center">
                            <EditIcon color="info" fontSize="medium" sx={{ cursor: "pointer" }} onClick={(() => editPartyHandler(val))} />
                            <DeleteIcon color="error" fontSize="medium" sx={{ marginLeft: "10px", cursor: "pointer" }} onClick={(() => {
                                setOpenDeleteModal(true)
                                setPartyId(val._id)
                                setType("")
                            })} />
                        </MDTypography>
                    ),
                }
            })
            : []
        setPartyDataColumn(column)
        setPartyDataRow(row)
        setIsShowLoading(false)
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            getPartyData()
        }
    }, [pageNumber])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pb={1} display="flex" justifyContent="end">
                <MDButton color="info" variant="gradient" onClick={clickHandlerOpen}> ADD </MDButton>
                <PartyModal
                    handleClose={clickHandlerClose}
                    openModal={openModal}
                    title={modalTitle}
                    formik={formik} />
                <ConfirmationModal
                    handleClose={clickHandlerClose}
                    openModal={openDeleteModal}
                    confirmDelete={type === "updateParty" ? partyActiveHandler : deletePartyData}
                    type={type}
                />
            </MDBox>
            {isShowLoading ?
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" size={20} />
                </MDBox>
                :
                <MDBox pb={2} >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox pt={1} style={{
                                    overflowY: "auto"
                                }}>
                                    <CollapsibleDataTable
                                        table={{ column: partyDataColumn, row: partyDataRow }}
                                        totalPage={totalPage}
                                        setPageNumber={setPageNumber}
                                        pageNumber={pageNumber}
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <Toaster />
            <Footer />
        </DashboardLayout>
    )
}
export default Party