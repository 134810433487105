import CloseIcon from '@mui/icons-material/Close';
import { FormControl } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import { FormikProvider } from 'formik';
import { GetPartyApi } from 'helper/api';
import { useEffect, useState } from 'react';

const PaymentModal = ({ openModal, title, formik, handleClose }) => {
    const [allPartyData, setAllPartyData] = useState([])

    const fieldStyle = {
        width: "100%",
        my: 1,
    }

    //for get Party Data..
    const partyData = async () => {
        const getParty = await GetPartyApi()
        setAllPartyData(getParty.data)
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            partyData()
        }
    }, [])

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="modal">
                <MDBox display="flex" alignItems="center" justifyContent="space-between" pb={1}>
                    <h3>{title}</h3>
                    <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
                </MDBox>
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik} >
                        <MDBox className="filedGrid">
                            <MDBox mt={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Party Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formik.values.party}
                                        label="Party Name"
                                        onChange={formik.handleChange}
                                        fullWidth
                                        sx={{ height: "44px" }}
                                        mt={4}
                                        name='party'
                                    >
                                        {allPartyData.map((val, index) => {
                                            return (
                                                <MenuItem value={val._id} key={index}>{val.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {formik.errors.party && formik.touched.party ? (
                                        <Box color="red" fontSize="13px">*{formik.errors.party}</Box>
                                    ) : <p></p>}
                                </FormControl>
                            </MDBox>
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} type="date" name="payment_date" value={formik.values.payment_date} onChange={formik.handleChange} />
                                <p style={{ color: "red", fontSize: "13px" }}> {formik.errors.payment_date && formik.touched.payment_date ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.payment_date}</Box>
                                ) : ""}</p>
                            </MDBox>
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} label="Amount" type="number" name="amount" value={formik.values.amount} onChange={formik.handleChange} />
                                {formik.errors.amount && formik.touched.amount ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.amount}</Box>
                                ) : null}
                            </MDBox>
                            <MDBox mt={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Payment Mode</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formik.values.payment_mode}
                                        label="Payment Mode"
                                        onChange={formik.handleChange}
                                        fullWidth
                                        sx={{ height: "44px" }}
                                        mt={4}
                                        name='payment_mode'
                                    >
                                        <MenuItem value="cash" >Cash</MenuItem>
                                        <MenuItem value="bank_transfer" >Bank Transfer</MenuItem>
                                        <MenuItem value="upi" >UPI</MenuItem>
                                        <MenuItem value="cheque" >Cheque</MenuItem>
                                    </Select>
                                    {formik.errors.payment_mode && formik.touched.payment_mode ? (
                                        <Box color="red" fontSize="13px">*{formik.errors.payment_mode}</Box>
                                    ) : <p></p>}
                                </FormControl>
                            </MDBox>
                            <MDBox mt={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formik.values.payment_type}
                                        label="Payment Type"
                                        onChange={formik.handleChange}
                                        fullWidth
                                        sx={{ height: "44px" }}
                                        mt={4}
                                        name='payment_type'
                                    >
                                        <MenuItem value="credit" >Credit</MenuItem>
                                        <MenuItem value="debit" >Debit</MenuItem>
                                    </Select>
                                    {formik.errors.payment_type && formik.touched.payment_type ? (
                                        <Box color="red" fontSize="13px">*{formik.errors.payment_type}</Box>
                                    ) : <p></p>}
                                </FormControl>
                            </MDBox>
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} label="Comment" type="text" name="comment" value={formik.values.comment} onChange={formik.handleChange} />
                                {formik.errors.comment && formik.touched.comment ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.comment}</Box>
                                ) : null}
                            </MDBox>
                        </MDBox>
                        <MDBox display="flex" justifyContent="end" pt={3}>
                            <MDButton variant="gradient" color="info" type="submit">
                                Save
                            </MDButton>
                        </MDBox>
                    </FormikProvider>
                </form>
            </Box>
        </Modal>
    )
}

export default PaymentModal