import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';

const UserModal = ({ openModal, handleClose, formik, title, isEdit = false }) => {
    const fieldStyle = {
        width: "100%",
        my: 1,
    }
    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="modal">
                <MDBox display="flex" alignItems="center" justifyContent="space-between" pb={1}>
                    <h3>{title}</h3>
                    <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
                </MDBox>
                <form onSubmit={formik.handleSubmit}>
                    <Box className="filedGrid">
                        <MDBox>
                            <TextField
                                variant="outlined"
                                sx={{ ...fieldStyle }}
                                label={'First Name'}
                                type={'text'}
                                name={'first_name'}
                                id='first_name'
                                value={formik.values.first_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.first_name && formik.touched.first_name ? (
                                <Box color="red" fontSize={13}>
                                    *{formik.errors.first_name}
                                </Box>
                            ) : null}
                        </MDBox>
                        <MDBox>
                            <TextField
                                variant="outlined"
                                sx={{ ...fieldStyle }}
                                label={'Last Name'}
                                type={'text'}
                                name={'last_name'}
                                id='last_name'
                                value={formik.values.last_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.errors.last_name && formik.touched.last_name ? (
                                <Box color="red" fontSize={13}>
                                    *{formik.errors.last_name}
                                </Box>
                            ) : null}
                        </MDBox>
                        <MDBox>
                            <TextField
                                variant="outlined"
                                sx={{ ...fieldStyle }}
                                label={'Email'}
                                type={'email'}
                                name={'email'}
                                id='email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={isEdit}
                            />
                            {formik.errors.email && formik.touched.email ? (
                                <Box color="red" fontSize={13}>
                                    *{formik.errors.email}
                                </Box>
                            ) : null}
                        </MDBox>
                        {!isEdit &&
                            <MDBox>
                                <TextField
                                    variant="outlined"
                                    sx={{ ...fieldStyle }}
                                    label={'Password'}
                                    type={'password'}
                                    name={'password'}
                                    id='password'
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.password && formik.touched.password ? (
                                    <Box color="red" fontSize={13}>
                                        *{formik.errors.password}
                                    </Box>
                                ) : null}
                            </MDBox>
                        }
                        <MDBox mt={1}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" >Role</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formik.values.role}
                                    label="Role"
                                    onChange={(event) => { formik.setFieldValue('role', event.target.value) }}
                                    fullWidth
                                    sx={{ height: "44px" }}
                                    mt={4}
                                    disabled={isEdit}
                                >
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="user">User</MenuItem>
                                </Select>
                                {formik.values.role.error && formik.values.role.touch ? (
                                    <Box color="red" fontSize={13}>*{formik.values.role.error}</Box>
                                ) : null}
                            </FormControl>
                        </MDBox>
                    </Box>
                    <MDBox display="flex" justifyContent="end" pt={3}>
                        <MDButton variant="gradient" color="info" type="submit">Save</MDButton>
                    </MDBox>
                </form>
            </Box>

        </Modal>
    )
}

export default UserModal