import InfoIcon from '@mui/icons-material/Info';
import { Box, Card, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Tooltip, capitalize } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { GetKhatawahiDataApi, GetPartyApi } from "helper/api";
import WithAppContext from "helper/context/app.ContextHoc";
import moment from "moment";
import PHE from "print-html-element";
import { useEffect, useState } from "react";
import 'react-calendar/dist/Calendar.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const KhataBook = () => {
    // const [isShowLoading, setIsShowLoading] = useState(true);
    const [party, setParty] = useState("")
    const [value, onChange] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const [outstandingAmount, setOutStandingAmount] = useState(0)
    const [orderTable, setOrderTable] = useState({
        orderDataColumn: [],
        orderDataRow: []
    })
    const [paymentTable, setPaymentTable] = useState({
        paymentDataColumn: [],
        paymentDataRow: []
    })
    const [orderSummaryTable, setOrderSummaryTable] = useState({
        orderSummaryDataColumn: [],
        orderSummaryDataRow: []
    })
    const [paymentSummaryTable, setPaymentSummaryTable] = useState({
        paymentSummaryDataColumn: [],
        paymentSummaryDataRow: []
    })
    const [pdfOrderTable, setPdfOrderTable] = useState({
        pdfOrderColumn: [],
        pdfOrderRow: []
    })
    const [pdfPaymentTable, setPdfPaymentTable] = useState({
        pdfPaymentColumn: [],
        pdfPaymentRow: []
    })
    const [allPartyData, setAllPartyData] = useState([])

    const handlePartyChange = (e) => setParty(e.target.value)

    function getPdfTableCells(data, type) {
        return data.map(cell => `<${type}>${cell.Header}</${type}>`).join('');
    }

    const createPdfTableBody = (data) => {
        return data.map((row, rowIndex) => {
            const cells = Object.values(row).map((cell, colIndex) => `<td key=${colIndex}>${cell}</td>`).join('');
            return `<tr key=${rowIndex}>${cells}</tr>`;
        }).join('');
    }

    let htmlString = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <style>
            body{
                width:100% !important;
            }
            table {
                border-collapse: collapse;
                width:100%
            }
            th {
                padding: 7px 6px;
                text-align: center;
                border-bottom: 1px solid black;
                font-size: 14px
            }
            td,tr {
                padding: 7px 6px;
                text-align: center;
                font-size: 14px
            }
            .pdf-container {
                width: 100%;
                display: flex;
                align-items:start; 
            }
            .pdf-table {
                margin: 3px; 
                width: 100%;          
            }
            .tablesSection{
                border-left: 1px solid black
            }

            @media print {
                body{
                    width:100% !important;
                }
                table {
                    border-collapse: collapse;
                    width:100%
                }
                th {
                    padding: 7px 6px;
                    text-align: center;
                    border-bottom: 1px solid black;
                    font-size: 14px
                }
                td,tr {
                    padding: 7px 6px;
                    text-align: center;
                    font-size: 14px
                }
                .pdf-container {
                    width: 100%;
                    display: flex;
                    align-items:start; 
                }
                .pdf-table {
                    margin: 3px; 
                    width: 100%;          
                }
                .tablesSection{
                    border-left: 1px solid black
                }
            }
        </style>
    </head> 
        <body>
        <div class="pdf-container">
            <div class="tableSection">
                <div class="pdf-table">
                    <table>
                        <thead>${getPdfTableCells((pdfPaymentTable.pdfPaymentColumn), 'th')}</thead>
                        <tbody>${createPdfTableBody((pdfPaymentTable.pdfPaymentRow))}</tbody>
                    </table>
                </div>
            </div>
            <div class="tablesSection">
                <div class="pdf-table">
                    <table>
                        <thead>${getPdfTableCells((pdfOrderTable.pdfOrderColumn), 'th')}</thead>
                        <tbody>${createPdfTableBody((pdfOrderTable.pdfOrderRow))}</tbody>
                    </table>
                </div>
            </div> 
        </div>  
        </body>
    </html>
  `

    const printPdfHandler = async () => {
        // PHE.printHtml(htmlString)

        // const printWindow = window.open('', '_blank');
        // printWindow.document.write(htmlString);
        // printWindow.document.close();
        // setTimeout(() => {
        //     printWindow.print(htmlString);
        //     printWindow.close();
        // }, 1000);

        // Create a hidden iframe to hold the printable content
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.contentDocument.write(htmlString);
        iframe.contentDocument.close();
        iframe.contentWindow.print();

        // Remove the iframe from the DOM after printing
        setTimeout(() => {
            document.body.removeChild(iframe);
        }, 1000);
    }

    const getKhataBookData = async (payload) => {
        const getKhatawahi = await GetKhatawahiDataApi(payload)
        setOutStandingAmount(getKhatawahi.data.overallSummary.outstanding_amount)
        const orderColumn = [
            { Header: "Date", accessor: "date", align: "center" },
            { Header: "Order Type", accessor: "order_type", align: "center" },
            { Header: "Material", accessor: "material_type", align: "center" },
            { Header: "Invoice Number", accessor: "invoice_number", align: "center" },
            { Header: "Quantity", accessor: "quantity", align: "center" },
            { Header: "Amount", accessor: "amount", align: "center" },
        ]
        const orderRow = getKhatawahi?.data?.ordersData
            ?
            getKhatawahi?.data?.ordersData?.map((val) => {
                return {
                    date: moment(val.delivery_date).format("DD/MM/YYYY"),
                    order_type: capitalize(val.order_type),
                    material_type: capitalize(val.materials?.material_type),
                    invoice_number: capitalize(val.invoice_number),
                    amount: (
                        <MDBox display="flex" alignItems="center">
                            <div>
                                {new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 2,
                                }).format(val?.materials?.amount || 0)}
                            </div>
                            {
                                val.comment && <Tooltip title={val.comment} style={{ cursor: "pointer", marginLeft: "5px", justifyContent: "center", alignItems: "center" }}>
                                    <InfoIcon fontSize="medium" />
                                </Tooltip>
                            }
                        </MDBox>)
                }
            })
            : []

        const paymentColumn = [
            { Header: "Date", accessor: "date", align: "center" },
            { Header: "Payment Type", accessor: "payment_type", align: "center" },
            { Header: "Amount", accessor: "amount", align: "center" },
        ]
        const paymentRow = getKhatawahi?.data?.paymentsData
            ?
            getKhatawahi?.data?.paymentsData?.map((val) => {
                return {
                    date: moment(val.payment_date).format("DD/MM/YYYY"),
                    payment_type: capitalize(val.payment_type),
                    amount:
                        <MDBox display="flex" alignItems="center">
                            <div>
                                {new Intl.NumberFormat("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 2,
                                }).format(val.amount)}
                            </div>
                            {
                                val.comment && <Tooltip title={val.comment} style={{ cursor: "pointer", marginLeft: "5px", justifyContent: "center", alignItems: "center" }}>
                                    <InfoIcon fontSize="medium" />
                                </Tooltip>
                            }
                        </MDBox>,
                }
            })
            :
            []

        const orderSummaryColumn = [
            { Header: "Material", accessor: "material", align: "left" },
            { Header: "Incoming Qty", accessor: "incoming_qty", align: "center", width: "22%" },
            { Header: "Outgoing Qty", accessor: "outgoing_qty", align: "center", width: "22%" },
            { Header: "Incoming Amount", accessor: "incoming_amount", align: "center", width: "22%" },
            { Header: "Outgoing Amount", accessor: "outgoing_amount", align: "center", width: "22%" },
            // { Header: "amount", accessor: "amount", align: "center" },
        ]
        const orderSummaryRow = getKhatawahi?.data?.materialSummary
            ? getKhatawahi?.data?.materialSummary?.map((val) => {
                return {
                    material: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {val.material_type}
                        </MDTypography>
                    ),
                    incoming_qty: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {val.incoming_quantity}
                        </MDTypography>
                    ),
                    outgoing_qty: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {val.outgoing_quantity}
                        </MDTypography>
                    ),
                    incoming_amount: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 2,
                            }).format(val.incoming_amount)}
                        </MDTypography>
                    ),
                    outgoing_amount: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 2,
                            }).format(val.outgoing_amount)}
                        </MDTypography>
                    ),

                }
            })
            :
            []

        const paymentSummaryColumn = [
            { Header: "Type", accessor: "summary", align: "center" },
            { Header: "Amount", accessor: "amount", align: "center" },
        ]
        const paymentSummaryRow = getKhatawahi?.data?.paymentSummary
            ?
            [
                {
                    summary: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Credit
                        </MDTypography>
                    ),
                    amount: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 2,
                            }).format(getKhatawahi?.data?.paymentSummary?.credit_total)}
                        </MDTypography>
                    ),
                },
                {
                    summary: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            Debit
                        </MDTypography>
                    ),
                    amount: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 2,
                            }).format(getKhatawahi?.data?.paymentSummary?.debit_total)}
                        </MDTypography>
                    ),
                }]
            :
            []

        setOrderTable({ ...orderTable, orderDataColumn: orderColumn.filter((val) => val.accessor !== "quantity" && val), orderDataRow: orderRow })
        setPaymentTable({ ...paymentTable, paymentDataColumn: paymentColumn, paymentDataRow: paymentRow })
        setOrderSummaryTable({ ...orderSummaryTable, orderSummaryDataColumn: orderSummaryColumn, orderSummaryDataRow: orderSummaryRow })
        setPaymentSummaryTable({ ...paymentSummaryTable, paymentSummaryDataColumn: paymentSummaryColumn, paymentSummaryDataRow: paymentSummaryRow })
        setPdfOrderTable({
            ...pdfOrderTable, pdfOrderColumn: orderColumn.filter((val) => val.accessor !== "order_type" && val),
            pdfOrderRow: getKhatawahi?.data?.ordersData.map((row) => ({
                date: moment(row.delivery_date).format("DD/MM/YYYY"),
                // order_type: row.order_type === "incoming" ? "In" : "Out",
                material_type: capitalize(row.materials?.material_type),
                invoice_number: row.invoice_number,
                quantity: `${row.materials?.qty_in_ton} Ton (${row.materials?.material_type === "cement" ? row.materials?.qty_in_ton * 20 + "Bgs" : row.materials?.qty_in_ton * 1000 + "Kgs"})`,
                amount: new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(row.materials?.amount || 0),
            }))
        })
        setPdfPaymentTable({
            ...pdfPaymentTable, pdfPaymentColumn: paymentColumn.map((val) => val), pdfPaymentRow: getKhatawahi?.data?.paymentsData.map((row) => ({
                date: moment(row.payment_date).format("DD/MM/YYYY"),
                payment_type: row.payment_type,
                amount: new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(row.amount)
            }))
        })
    }

    useEffect(() => {
        const payload = {
            filters: {
                party: party && party,
                date: value && value
            }
        }
        if (party && value) {
            getKhataBookData(payload)
        }
    }, [party, value])

    //for get Party Data..
    const partyData = async () => {
        const getParty = await GetPartyApi()
        setAllPartyData(getParty.data)
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            partyData()
        }
    }, [])

    const findParty = allPartyData.find((val) => val._id === party)

    return (
        <DashboardLayout >
            <DashboardNavbar />
            {party ?
                <>
                    <MDBox className="khataBook">
                        <MDBox style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", }}
                            onClick={() => {
                                setParty("")
                                onChange([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)])
                            }} >
                            <ArrowBackIcon fontSize='medium' />
                        </MDBox>
                        <MDBox className="khatabookHead">
                            <MDBox>
                                <MDTypography>
                                    <Box style={{ marginRight: 3, fontWeight: 700, fontSize: "17px" }}> Party Name: <span style={{ marginRight: 3, fontWeight: 400 }}>{findParty.name}</span></Box>
                                </MDTypography>
                            </MDBox>
                            <MDBox className="khataBookDateSection">
                                <DateRangePicker
                                    onChange={onChange}
                                    value={value}
                                    format="d/M/y"
                                    monthPlaceholder="mm"
                                    dayPlaceholder="d"
                                    yearPlaceholder="yyyy"
                                    autoFocus={false}
                                />
                                <MDBox className="printBtn">
                                    <MDButton color="info" variant="gradient" onClick={printPdfHandler} className="btn"> Print </MDButton>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    {/* {isShowLoading ?
                <CircularProgress color="white" size={20} />
                : */}
                    <MDBox sx={{ overflowY: "auto" }}>
                        <MDBox >
                            <Grid container>
                                <Grid item xs={12} >
                                    <MDBox style={{ display: "flex" }} className="khataBookGridTable">
                                        <MDBox className="khatBookTableSection">
                                            <Grid>
                                                <DataTable
                                                    table={{ column: paymentTable.paymentDataColumn, row: paymentTable.paymentDataRow }}
                                                    isSorted={false}
                                                    entriesPerPage={false}
                                                    showTotalEntries={false}
                                                    noEndBorder
                                                    tableHeight="35vh"
                                                    id="paymentTable"
                                                />
                                            </Grid>
                                            <Grid pt={1}>
                                                <DataTable
                                                    table={{ column: paymentSummaryTable.paymentSummaryDataColumn, row: paymentSummaryTable.paymentSummaryDataRow }}
                                                    isSorted={false}
                                                    entriesPerPage={false}
                                                    showTotalEntries={false}
                                                    noEndBorder
                                                    tableHeight="33vh"
                                                    id="orderTable"
                                                />
                                            </Grid>
                                        </MDBox>
                                        <MDBox className="khatBookTableSection">
                                            <Grid>
                                                <DataTable
                                                    table={{ column: orderTable.orderDataColumn, row: orderTable.orderDataRow }}
                                                    isSorted={false}
                                                    entriesPerPage={false}
                                                    showTotalEntries={false}
                                                    noEndBorder
                                                    tableHeight="35vh"
                                                />
                                            </Grid>
                                            <Grid pt={1}>
                                                <DataTable
                                                    table={{ column: orderSummaryTable.orderSummaryDataColumn, row: orderSummaryTable.orderSummaryDataRow }}
                                                    isSorted={false}
                                                    entriesPerPage={false}
                                                    showTotalEntries={false}
                                                    noEndBorder
                                                    tableHeight="33vh"
                                                />
                                            </Grid>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox mt={1} >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Card>
                                                    <MDBox py={1} display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems={{ xs: "center" }} justifyContent={{ xs: "center" }} sx={{
                                                        overflowY: "auto",
                                                    }}>
                                                        <MDTypography sx={{ fontWeight: 700 }}>
                                                            Outstanding Amount
                                                        </MDTypography>
                                                        <MDBox ml={{ sm: 2 }} mr={{ sm: 2 }}>
                                                            -
                                                        </MDBox>
                                                        <MDTypography sx={{ fontWeight: 700 }}>
                                                            {new Intl.NumberFormat("en-IN", {
                                                                style: "currency",
                                                                currency: "INR",
                                                                minimumFractionDigits: 2,
                                                            }).format(outstandingAmount ? outstandingAmount : 0)}
                                                        </MDTypography>
                                                    </MDBox>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </MDBox>
                    {/* } */}
                </>
                :
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: "70vh" }}>
                    <Paper elevation={3} sx={{ padding: 4 }}>
                        <MDBox display={{ sm: "flex" }} alignItems="center">
                            <MDBox>
                                <h4>Choose Party: &nbsp;&nbsp;</h4>
                            </MDBox>
                            <MDBox>
                                <FormControl sx={{ width: "200px" }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Party Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={party}
                                        label="Party Name"
                                        onChange={handlePartyChange}
                                        fullWidth
                                        sx={{ height: "44px", }}
                                        name='party'
                                    >
                                        {allPartyData.map((val, index) => {
                                            return (
                                                <MenuItem value={val._id} key={index}>{val.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </MDBox>
                        </MDBox>
                    </Paper>
                </Box>
            }
        </DashboardLayout >
    )
}

export default WithAppContext(KhataBook);