import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Pagination, TableFooter, capitalize } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useEffect, useState } from 'react';

const Row = ({ row, type }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left" aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ cursor: "pointer" }}
                >{row.party_name}</TableCell>
                <TableCell align="center"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ cursor: "pointer" }}
                >{row.truck_number}</TableCell>
                <TableCell align="center"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ cursor: "pointer" }}
                >{row.transport_name}</TableCell>
                <TableCell align="center"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ cursor: "pointer" }}
                >{row.deliver_date}</TableCell>
                <TableCell align="center"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ cursor: "pointer" }}
                >{row.invoice_number}</TableCell>
                {type !== "incoming" && <TableCell align="center">{row.comment}</TableCell>}
                <TableCell align="right">{row.action}</TableCell>
                <TableCell align="right">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12} >
                    <Collapse in={open} timeout="auto" style={{ width: "100%" }} >
                        <Box sx={{ margin: 2 }}>
                            <TableContainer style={{ marginTop: "20px", marginBottom: "20px" }} >
                                <Table aria-label="simple table">
                                    <TableHead style={{ display: "table-header-group" }}>
                                        <TableRow >
                                            <TableCell align="left" style={{ fontWeight: 700, fontSize: "0.70rem", }}>
                                                No
                                            </TableCell>
                                            <TableCell align="left" style={{ fontWeight: 700, fontSize: "0.70rem" }}>
                                                Materials Type
                                            </TableCell>
                                            <TableCell align="left" style={{ fontWeight: 700, fontSize: "0.70rem" }}>
                                                Quantity(In Ton)
                                            </TableCell>
                                            <TableCell align="left" style={{ fontWeight: 700, fontSize: "0.70rem" }}>
                                                Weight(In Ton)
                                            </TableCell>
                                            <TableCell align="left" style={{ fontWeight: 700, fontSize: "0.70rem" }}>
                                                Rate(In Ton)
                                            </TableCell>
                                            <TableCell align="left" style={{ fontWeight: 700, fontSize: "0.70rem" }}>
                                                Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.materials?.map((val, index) => (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                                                <TableCell component="th" scope="row">
                                                    <MDTypography variant="caption" color="text" fontWeight="medium">
                                                        {index + 1}
                                                    </MDTypography>
                                                </TableCell>
                                                <TableCell align="left"><MDTypography variant="caption" color="text" fontWeight="medium">{capitalize(val.material_type)}</MDTypography></TableCell>
                                                <TableCell align="left"><MDTypography variant="caption" color="text" fontWeight="medium">{val.qty_in_ton}</MDTypography></TableCell>
                                                <TableCell align="left"><MDTypography variant="caption" color="text" fontWeight="medium">{val.weight}</MDTypography></TableCell>
                                                <TableCell align="left"><MDTypography variant="caption" color="text" fontWeight="medium">{val.rate}</MDTypography></TableCell>
                                                <TableCell align="left"><MDTypography variant="caption" color="text" fontWeight="medium">{new Intl.NumberFormat("en-IN", {
                                                    style: "currency",
                                                    currency: "INR",
                                                    minimumFractionDigits: 2,
                                                }).format(val.amount)}</MDTypography></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow >
        </>
    );
}

const OrderCollapsibleDataTable = ({ table, pageNumber, setPageNumber, totalPage, type }) => {

    const handleChange = (e, p) => {
        setPageNumber(p);
    }

    useEffect(() => {
        if (table.row.length === 0 && pageNumber > 1) {
            const newPageNumber = Math.max(1, pageNumber - 1)
            setPageNumber(newPageNumber);
        }
    }, [table.row.length]);

    return (
        <TableContainer component={Paper} style={{ width: "100%", height: "70vh" }}>
            <Table aria-label="simple table">
                <TableHead style={{ display: "table-header-group" }}>
                    <TableRow>
                        {table.column.map((val, index) => (
                            <TableCell key={index} align={val.align} style={{ fontWeight: 700, fontSize: "0.65rem", paddingBottom: "0.75rem", paddingLeft: "0.6rem", width: "max-content" }}>
                                {val?.Header?.toUpperCase()}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {table.row.length > 0
                    ?
                    <TableBody>
                        {table.row.map((val, index) => (
                            <Row key={index} row={val} type={type} />
                        ))}
                        {totalPage > 1 && <TableRow style={{ height: 50 }} />}
                    </TableBody>
                    :
                    <TableBody style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
                        <TableRow >
                            No data found
                        </TableRow>
                    </TableBody>
                }
                {totalPage > 1 &&
                    <TableFooter>
                        <tr>
                            <MDBox component="td" style={{
                                display: "flex", justifyContent: "center", alignItems: "center",
                                position: "absolute", width: "100%", backgroundColor: "white", bottom: 0, padding: 5
                                // borderBottomRightRadius:15, borderBottomLeftRadius:15
                            }}>
                                <Pagination
                                    count={totalPage}
                                    size="medium"
                                    color="info"
                                    page={pageNumber}
                                    onChange={handleChange}
                                />
                            </MDBox>
                        </tr>
                    </TableFooter>
                }
            </Table>
        </TableContainer >
    );
}

export default OrderCollapsibleDataTable