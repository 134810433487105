// Material Dashboard 2 React layouts
import SignIn from "layouts/authentication/sign-in";
import Dashboard from "layouts/dashboard";

// @mui icons
import Icon from "@mui/material/Icon";
import KhataBook from "layouts/khataBook";
import IncomingOrder from "layouts/incomingOrder";
import OutgoingOrder from "layouts/outgoingOrder";
import OutstandingList from "layouts/outstandingList";
import Party from "layouts/party";
import Payment from "layouts/payment";
import Users from "layouts/users";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Incoming Order",
    key: "incoming-order",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/incoming-order",
    component: <IncomingOrder />,
  },
  {
    type: "collapse",
    name: "Outgoing Order",
    key: "outgoing-order",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/outgoing-order",
    component: <OutgoingOrder />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">groups</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Payment",
    key: "payment",
    icon: <Icon fontSize="small">payment</Icon>,
    route: "/payment",
    component: <Payment />,
  },
  {
    type: "collapse",
    name: "Party",
    key: "party",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/party",
    component: <Party />,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">account_circle</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Outstanding List",
    key: "outstanding-list",
    icon: <Icon fontSize="small">checklistRtlIcon</Icon>,
    route: "/outstanding-list",
    component: <OutstandingList />,
  },
  {
    type: "collapse",
    name: "Khatabook",
    key: "khatabook",
    icon: <Icon fontSize="small">eventNote</Icon>,
    route: "/khatabook",
    component: <KhataBook />,
  },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-in",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  }
];

export default routes;
