import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, Typography } from "@mui/material";
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

const ConfirmationModal = ({ openModal, handleClose, confirmDelete, type }) => {

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="modal" maxWidth={450}>
                <Box display="flex" alignItems="center" justifyContent="end" pb={1}>
                    <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" pb={1}>
                    <Typography className=''>
                        {type ? " Are you sure to want change status?" : "Are you sure to want delete this?"}
                    </Typography>
                </Box>
                <MDBox display="flex" justifyContent="space-around" alignItems="center" pt={3} pb={2} maxWidth={"100%"} >
                    <MDButton variant="gradient" size="medium" color="info" type="submit" onClick={handleClose}>
                        No
                    </MDButton>
                    <MDButton variant="gradient" size="medium" color="info" type="submit" onClick={confirmDelete}>
                        Yes
                    </MDButton>
                </MDBox>
            </Box>
        </Modal>
    )
}

export default ConfirmationModal