//API URL
export const API_URL = '/auth/login';
export const LOGIN_API_URL = '/auth/login';

export const CREATE_USER_API_URL = '/user/create';
export const GET_ALL_USER_URL = '/user/all';
export const GET_USER_BY_ID_URL = '/user/';
export const UPDATE_USER_URL = '/user/update';
export const CHANGE_PASSWORD_URL = '/auth/change-password';

export const CREATE_PARTY_API_URL = '/party/create';
export const GET_ALL_PARTY_URL = '/party/all';
export const GET_PARTY_BY_ID_URL = '/party/';
export const UPDATE_PARTY_URL = '/party/update';
export const DELETE_PARTY_URL = '/party/';

export const CREATE_INCOMING_ORDER_API_URL = '/order/incoming/create';
export const GET_ALL_INCOMING_ORDER_URL = '/order/incoming/all';
export const GET_INCOMING_ORDER_BY_ID_URL = '/order/incoming/';
export const UPDATE_INCOMING_ORDER_URL = '/order/incoming/update';
export const DELETE_INCOMING_ORDER_URL = '/order/incoming/';

export const CREATE_OUTGOING_ORDER_API_URL = '/order/outgoing/create';
export const GET_ALL_OUTGOING_ORDER_URL = '/order/outgoing/all';
export const GET_OUTGOING_ORDER_BY_ID_URL = '/order/outgoing/';
export const UPDATE_OUTGOING_ORDER_URL = '/order/outgoing/update';
export const DELETE_OUTGOING_ORDER_URL = '/order/outgoing/';

export const CREATE_PAYMENT_API_URL = '/payment/create';
export const GET_ALL_PAYMENT_URL = '/payment/all';
export const GET_PAYMENT_BY_ID_URL = '/payment/';
export const UPDATE_PAYMENT_URL = '/payment/update';
export const DELETE_PAYMENT_URL = '/payment/';

export const GET_ALL_OUTSTANDING_LIST = '/khata/outstanding-list';

export const GET_KHATAWAHI_DATA = '/khata/all';
