import {
  LOGIN_API_URL,
  GET_ALL_USER_URL,
  CREATE_USER_API_URL,
  GET_USER_BY_ID_URL,
  UPDATE_USER_URL,
  CHANGE_PASSWORD_URL,
  CREATE_PARTY_API_URL,
  GET_PARTY_BY_ID_URL,
  GET_ALL_PARTY_URL,
  UPDATE_PARTY_URL,
  DELETE_PARTY_URL,
  CREATE_INCOMING_ORDER_API_URL,
  GET_ALL_INCOMING_ORDER_URL,
  GET_INCOMING_ORDER_BY_ID_URL,
  UPDATE_INCOMING_ORDER_URL,
  DELETE_INCOMING_ORDER_URL,
  CREATE_OUTGOING_ORDER_API_URL,
  GET_ALL_OUTGOING_ORDER_URL,
  GET_OUTGOING_ORDER_BY_ID_URL,
  UPDATE_OUTGOING_ORDER_URL,
  DELETE_OUTGOING_ORDER_URL,
  CREATE_PAYMENT_API_URL,
  GET_ALL_PAYMENT_URL,
  GET_PAYMENT_BY_ID_URL,
  UPDATE_PAYMENT_URL,
  DELETE_PAYMENT_URL,
  GET_ALL_OUTSTANDING_LIST,
  GET_KHATAWAHI_DATA
} from './apiURL';
import { API } from './axios';

export const loginApi = async (params) => {
  try {
    const response = await API.post(LOGIN_API_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

//For User API..
export const CreateUserApi = async (params) => {
  try {
    const response = await API.post(CREATE_USER_API_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const UpdateUserApi = async (params) => {
  try {
    const response = await API.post(UPDATE_USER_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const ChangePasswordApi = async (params) => {
  try {
    const response = await API.post(CHANGE_PASSWORD_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetUserApi = async (params) => {
  try {
    let url = GET_ALL_USER_URL;
    if (params) {
      url = url + `?page=${params}&limit=10`;
    }
    const response = await API.get(url);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetUserByIdApi = async (userID) => {
  try {
    const response = await API.get(GET_USER_BY_ID_URL + `${userID}`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

//For Party API...
export const CreatePartyApi = async (params) => {
  try {
    const response = await API.post(CREATE_PARTY_API_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetPartyApi = async (params) => {
  try {
    let url = GET_ALL_PARTY_URL;
    if (params) {
      url = url + `?page=${params}&limit=10`;
    }
    const response = await API.get(url);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetPartyByIdApi = async (partyID) => {
  try {
    const response = await API.get(GET_PARTY_BY_ID_URL + `${partyID}`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const UpdatePartyApi = async (params) => {
  try {
    const response = await API.post(UPDATE_PARTY_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const deletePartyApi = async (orderID) => {
  try {
    const response = await API.delete(DELETE_PARTY_URL + `${orderID}`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

//For Incoming Order API..
export const CreateIncomingOrderApi = async (params) => {
  try {
    const response = await API.post(CREATE_INCOMING_ORDER_API_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetIncomingOrderApi = async (params) => {
  try {
    let url = GET_ALL_INCOMING_ORDER_URL;
    if (params) {
      url = url + `?page=${params}&limit=10`;
    }
    const response = await API.get(url);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetIncomingOrderByIdApi = async (orderID) => {
  try {
    const response = await API.get(GET_INCOMING_ORDER_BY_ID_URL + `${orderID}`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const UpdateIncomingOrderApi = async (params) => {
  try {
    const response = await API.post(UPDATE_INCOMING_ORDER_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const deleteIncomingOrderApi = async (orderID) => {
  try {
    const response = await API.delete(DELETE_INCOMING_ORDER_URL + `${orderID}`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

//For Outgoing Order API..
export const CreateOutgoingOrderApi = async (params) => {
  try {
    const response = await API.post(CREATE_OUTGOING_ORDER_API_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetOutgoingOrderApi = async (params) => {
  try {
    const response = await API.get(GET_ALL_OUTGOING_ORDER_URL + `?page=${params}&limit=10`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetOutgoingOrderByIdApi = async (orderID) => {
  try {
    const response = await API.get(GET_OUTGOING_ORDER_BY_ID_URL + `${orderID}`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const UpdateOutgoingOrderApi = async (params) => {
  try {
    const response = await API.post(UPDATE_OUTGOING_ORDER_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const deleteOutgoingOrderApi = async (paymentID) => {
  try {
    const response = await API.delete(DELETE_OUTGOING_ORDER_URL + `${paymentID}`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

//For Payment API..
export const CreatePaymentApi = async (params) => {
  try {
    const response = await API.post(CREATE_PAYMENT_API_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetPaymentApi = async (params) => {
  try {
    const response = await API.get(GET_ALL_PAYMENT_URL + `?page=${params}&limit=10`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetPaymentByIdApi = async (orderID) => {
  try {
    const response = await API.get(GET_PAYMENT_BY_ID_URL + `${orderID}`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const UpdatePaymentApi = async (params) => {
  try {
    const response = await API.post(UPDATE_PAYMENT_URL, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const deletePaymentApi = async (orderID) => {
  try {
    const response = await API.delete(DELETE_PAYMENT_URL + `${orderID}`);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};

export const GetOutstandingListApi = async (params) => {
  try {
    const response = await API.post(GET_ALL_OUTSTANDING_LIST, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};


export const GetKhatawahiDataApi = async (params) => {
  try {
    const response = await API.post(GET_KHATAWAHI_DATA, params);
    return { ...response.data, status: response.status };
  } catch (error) {
    return error;
  }
};
