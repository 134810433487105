import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CircularProgress, Grid } from "@mui/material";
import UserAvatar from "assets/images/userAvatar.png";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ConfirmationModal from 'examples/Modals/ConfirmationModal';
import OrderModal from "examples/Modals/OrderModal";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import OrderCollapsibleDataTable from "examples/Tables/OrderCollapsibleDataTable";
import Toaster, { showToastMessage } from "examples/Toaster";
import { useFormik } from 'formik';
import { CreateIncomingOrderApi, GetIncomingOrderApi, UpdateIncomingOrderApi, deleteIncomingOrderApi } from "helper/api";
import moment from "moment";
import { useEffect, useState } from "react";
import * as Yup from 'yup';

const validSchema = Yup.object().shape({
    partyName: Yup.string().required("Party name is required"),
    truckNumber: Yup.string().required("Truck name is required"),
    transportName: Yup.string().required("Transport name is required"),
    deliveryDate: Yup.string().required("Date is required"),
    invoiceNumber: Yup.string().required("Invoice number is required"),
    materials: Yup.array().of(Yup.object().shape({
        material_type: Yup.string().required("Material type is required"),
        // qty_in_ton: Yup
        //     .number('Only allow number').positive("Please enter positive value").required("Quantity is required"),
        qty_in_ton: Yup.number('Only allow number').when('material_type', (value, field) => {
            if (["steel", "wire"].includes(value[0])) {
                return field.positive("Please enter positive value")
            } else {
                return field.positive("Please enter positive value").required("Quantity is required")
            }
        }),
        // rate: Yup.number('Only allow number').positive("Please enter positive value").required("Rate is required"),
        rate: Yup.number('Only allow number').when('material_type', (value, field) => {
            if (["steel", "wire"].includes(value[0])) {
                return field.positive("Please enter positive value")
            } else {
                return field.positive("Please enter positive value").required("Rate is required")
            }
        }),
        // weight: Yup.number('Only allow number').positive("Please enter positive value").required("Weight is required"),
        weight: Yup.number('Only allow number').when('material_type', (value, field) => {
            if (["steel", "wire"].includes(value[0])) {
                return field.positive("Please enter positive value")
            } else {
                return field.positive("Please enter positive value").required("Weight is required")
            }
        }),
        amount: Yup.number('Only allow number').when('material_type', (value, field) => {
            if (["steel", "wire"].includes(value[0])) {
                return field.positive("Please enter positive value").required("Amount is required")
            } else {
                return field.positive("Please enter positive value")
            }
        }),
    })).required().min(1)
});

const IncomingOrder = () => {
    const [openModal, setOpenModal] = useState(false);
    const [incomingDataRow, setIncomingDataRow] = useState([]);
    const [incomingDataColumn, setIncomingDataColumn] = useState([]);
    const [isShowLoading, setIsShowLoading] = useState(true);
    const [modalTitle, setModalTitle] = useState("Create Incoming Order")
    const [editOrderData, setEditOrderData] = useState({})
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [orderId, setOrderId] = useState("")

    // For Create Incoming Order..
    const createIncomingOrderData = async (values) => {
        const createIncomingOrder = await CreateIncomingOrderApi(values)
        if (createIncomingOrder.status === 200) {
            showToastMessage(createIncomingOrder.message, 'success')
        } else {
            showToastMessage(createIncomingOrder.message, 'error')
        }
    }

    //For Update Order..
    const updateIncomingOrderData = async (values) => {
        const updateParty = await UpdateIncomingOrderApi(values)
        if (updateParty.status === 200) {
            showToastMessage(updateParty.message, 'success')
        } else {
            showToastMessage(updateParty.message, 'error')
        }
    }

    //For Delete  Incoming Order..
    const deleteIncomingOrderData = async () => {
        await deleteIncomingOrderApi(orderId)
        getIncomingOrderData(pageNumber);
        setOpenDeleteModal(false);
        showToastMessage("Delete Incoming Order Successfully", 'success')
    }

    const formik = useFormik({
        initialValues: {
            partyName: "",
            truckNumber: '',
            transportName: '',
            deliveryDate: "",
            invoiceNumber: "",
            materials: [
                {
                    material_type: "",
                    qty_in_ton: "",
                    weight: "",
                    rate: "",
                    amount: "",
                }
            ]
        },
        onSubmit: async (values, { resetForm }) => {
            if (modalTitle === "Edit Incoming Order") {
                let payload = {
                    order_id: editOrderData._id,
                    delivery_date: new Date(values.deliveryDate),
                    party: values.partyName,
                    truck_number: values.truckNumber,
                    transport_name: values.transportName,
                    invoice_number: values.invoiceNumber,
                    materials: values.materials.map(material => ({
                        material_type: material.material_type,
                        qty_in_ton: material.qty_in_ton && material.qty_in_ton !== "" ? material.qty_in_ton : undefined,
                        rate: material.rate && material.rate !== "" ? material.rate : undefined,
                        amount: material.amount,
                    })),
                }
                await updateIncomingOrderData(payload)
            } else {
                let payload = {
                    delivery_date: new Date(values.deliveryDate),
                    party: values.partyName,
                    truck_number: values.truckNumber,
                    transport_name: values.transportName,
                    invoice_number: values.invoiceNumber,
                    materials: values.materials.map(material => ({
                        material_type: material.material_type,
                        qty_in_ton: material.qty_in_ton && material.qty_in_ton !== "" ? material.qty_in_ton : undefined,
                        rate: material.rate && material.rate !== "" ? material.rate : undefined,
                        amount: material.amount,
                    })),
                }
                await createIncomingOrderData(payload)
            }
            resetForm()
            clickHandlerClose()
            await getIncomingOrderData(pageNumber)
        },
        validationSchema: validSchema
    })

    const clickHandlerOpen = () => {
        setOpenModal(true);
        setModalTitle("Create Incoming Order")
    }

    const clickHandlerClose = () => {
        setOpenModal(false);
        formik.resetForm()
        setOpenDeleteModal(false);
    };

    const editOrderHandler = (values) => {
        setOpenModal(true);
        setModalTitle("Edit Incoming Order")
        setEditOrderData(values)
        formik.setValues({
            partyName: values.party._id,
            truckNumber: values.truck_number,
            transportName: values.transport_name,
            deliveryDate: moment(new Date(values.delivery_date)).format('YYYY-MM-DD'),
            invoiceNumber: values.invoice_number,
            materials: values.materials.map(material => ({
                ...material,
                // rate: material?.material_type === "cement" ? material?.amount / (material?.qty_in_ton * 20) : material?.amount / (material?.qty_in_ton * 1000),
                rate: getMaterialRate(material),
                weight: material?.qty_in_ton ? material?.material_type === "cement" ? material?.qty_in_ton * 20 : material?.qty_in_ton * 1000 : "",
            }))
        })
    }

    const getMaterialRate = (material) => {
        switch (material.material_type) {
            case "cement":
                return new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                }).format(material?.amount / (material?.qty_in_ton * 20))
            // return material?.amount / (material?.qty_in_ton * 1000)
            case "wire":
            case "steel":
                return new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                }).format(material?.rate)
            default:
                return ""
        }
    }

    //For Get All Incoming Order..
    const getIncomingOrderData = async () => {
        const getIncomingOrder = await GetIncomingOrderApi(pageNumber)
        setTotalPage(getIncomingOrder.totalPage)
        const Name = ({ image, name }) => (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
                <MDAvatar src={image} name={name} size="sm" />
                <MDBox ml={2} lineHeight={1}>
                    <MDTypography display="block" variant="button" fontWeight="medium">
                        {name}
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
        const column = [
            { Header: "party name", accessor: "party_name", width: "28%", align: "center" },
            { Header: "truck number", accessor: "truck_number", align: "center" },
            { Header: "transport name", accessor: "transport_name", align: "center" },
            { Header: "deliver date", accessor: "deliver_date", align: "center" },
            { Header: "invoice number", accessor: "invoice_number", align: "center" },
            { Header: "action", accessor: "action", align: "center" },
            { Header: "", accessor: "", align: "right" },
        ]

        const row = getIncomingOrder.data.map((val) => {
            return {
                party_name: <Name image={UserAvatar} name={val.party?.name} val={val} />,
                truck_number: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {val.truck_number.toUpperCase()}
                    </MDTypography>
                ),
                transport_name: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {val.transport_name}
                    </MDTypography>
                ),
                deliver_date: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {moment(val.delivery_date).format("DD/MM/YYYY")}
                    </MDTypography>
                ),
                invoice_number: (
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                        {val.invoice_number}
                    </MDTypography>
                ),
                materials: val.materials.map(material => ({
                    ...material,
                    // rate: material?.material_type === "cement" ? material?.amount / (material?.qty_in_ton * 20) : material?.amount / (material?.qty_in_ton * 1000),
                    rate: getMaterialRate(material),
                    weight: material?.qty_in_ton ? material?.material_type === "cement" ? material?.qty_in_ton * 20 : material?.qty_in_ton * 1000 : "",
                })),
                action: (
                    <MDTypography variant="caption" color="text" fontWeight="medium" display="flex" alignItems="center" justifyContent="center">
                        <EditIcon color="info" fontSize="medium" sx={{ cursor: "pointer" }} onClick={(() => editOrderHandler(val))} />
                        <DeleteIcon color="error" fontSize="medium" sx={{ marginLeft: "10px", cursor: "pointer" }} onClick={(() => {
                            setOpenDeleteModal(true)
                            setOrderId(val._id)
                        })} />
                    </MDTypography>
                ),
            }
        })
        setIncomingDataColumn(column)
        setIncomingDataRow(row)
        setIsShowLoading(false)
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            getIncomingOrderData()
        }
    }, [pageNumber])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pb={1} display="flex" justifyContent="end">
                <MDButton color="info" variant="gradient" onClick={clickHandlerOpen}> ADD </MDButton>
                <OrderModal
                    handleClose={clickHandlerClose}
                    openModal={openModal}
                    title={modalTitle}
                    formik={formik}
                />
                <ConfirmationModal
                    handleClose={clickHandlerClose}
                    openModal={openDeleteModal}
                    confirmDelete={deleteIncomingOrderData}
                />
            </MDBox>
            {isShowLoading ?
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" size={20} />
                </MDBox>
                :
                <MDBox pb={2} >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox pt={1} style={{
                                    overflowY: "auto"
                                }}>
                                    <OrderCollapsibleDataTable
                                        table={{ column: incomingDataColumn, row: incomingDataRow }}
                                        totalPage={totalPage}
                                        setPageNumber={setPageNumber}
                                        pageNumber={pageNumber}
                                        type="incoming"
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <Toaster />
            <Footer />
        </DashboardLayout>
    )
}

export default IncomingOrder