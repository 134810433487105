import { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import { CircularProgress } from '@mui/material';
import Toaster, { showToastMessage } from 'examples/Toaster';
import { loginApi } from 'helper/api';
import 'react-toastify/dist/ReactToastify.css';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,20}$/,
      'Must be contain at least 1 lower case, 1 upper case, 1 digit and 1 special character '
    )
    .required('Password is required'),
  email: Yup.string()
    .trim()
    .email('Please enter valid email format')
    .required('Email is required')
    .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+.(?:\.[a-zA-Z0-9-]+)*$/, 'Please enter valid email format')
});

function Basic() {
  const [isLoad, setIsLoad] = useState(false);
  const navigate = useNavigate();

  //For Fetch Login Data From API
  const handleLogin = async (values) => {
    setIsLoad(true);
    const response = await loginApi(values);
    if (response.status === 200) {
      let { user, token } = response.data;
      localStorage.setItem('accessToken', token);
      localStorage.setItem('user', JSON.stringify(user));
      showToastMessage(response.message, 'success');
      setTimeout(() => {
        setIsLoad(false);
        navigate('/dashboard');
      }, 2100)
    } else {
      setIsLoad(false);
      showToastMessage(response.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      handleLogin(values);
    },
    validationSchema: validationSchema
  });

  return <BasicLayout image={bgImage}>
    <Card>
      <MDBox textAlign="center">
        <MDTypography variant="h4" fontWeight="medium" color="dark" mt={4}>
          Sign in
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox>
          <form onSubmit={formik.handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email ? (
                <Box color="red" fontSize={15}>
                  *{formik.errors.email}
                </Box>
              ) : (
                ''
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password ? (
                <Box color="red" fontSize={15}>
                  *{formik.errors.password}
                </Box>
              ) : (
                ''
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                {isLoad ? <CircularProgress color="white" size={20} /> : 'sign in'}
              </MDButton>
            </MDBox>
          </form>
          {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  onClick={() => navigate('/authentication/sign-up')}
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
        </MDBox>
      </MDBox>
    </Card>
    <Toaster />
  </BasicLayout>
}

export default Basic;
