import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { FormControl } from "@mui/material";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import { FieldArray, FormikProvider } from 'formik';

const PartyModal = ({ openModal, title, formik, handleClose }) => {

    const fieldStyle = {
        width: "100%",
        my: 1,
    }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="modal">
                <MDBox display="flex" alignItems="center" justifyContent="space-between" pb={1}>
                    <h3>{title}</h3>
                    <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
                </MDBox>
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <MDBox className="filedGrid">
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} label="Party Name" type="text" name="name" value={formik.values.name} onChange={formik.handleChange} />
                                {formik.errors.name && formik.touched.name ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.name}</Box>
                                ) : null}
                            </MDBox>
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} label="Contact Number" type="text" name="contact_number" value={formik.values.contact_number} onChange={formik.handleChange} />
                                {formik.errors.contact_number && formik.touched.contact_number ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.contact_number}</Box>
                                ) : null}
                            </MDBox>
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} label="Contact Person Name" type="text" name="contact_person_name" value={formik.values.contact_person_name} onChange={formik.handleChange} />
                                {formik.errors.contact_person_name && formik.touched.contact_person_name ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.contact_person_name}</Box>
                                ) : null}
                            </MDBox>
                            <MDBox mt={1}>
                                <FormControl fullWidth>
                                    <MDBox display="flex" height="44px">
                                        <Select id="cars" className="selectField" style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0, width: "13%", fontSize: 20, height: "44px" }}
                                            value={formik.values.type}
                                            onChange={formik.handleChange}
                                            name="type"
                                        >
                                            <MenuItem value="+">+</MenuItem>
                                            <MenuItem value="-">-</MenuItem>
                                        </Select>
                                        <input placeholder="Opening Balance" type="number" name="amount"
                                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                            value={formik.values.amount}
                                            onChange={formik.handleChange}
                                            className="openingBalance" />
                                    </MDBox>
                                    <MDBox>
                                        {formik.errors.amount && formik.touched.amount ? (
                                            <Box color="red" fontSize="13px">*{formik.errors.amount}</Box>
                                        ) : null}
                                    </MDBox>
                                </FormControl>
                            </MDBox>
                        </MDBox>
                        <MDBox>
                            <MDBox display="flex" alignItems="center" justifyContent="space-between" my={1}>
                                <MDBox fontSize={20}>Delivery Locations :</MDBox>
                                <AddIcon color="info" fontSize="medium" sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                        const deliveryLocationsClone = [...formik.values.delivery_locations]
                                        deliveryLocationsClone.push("")
                                        formik.setFieldValue('delivery_locations', deliveryLocationsClone)
                                    }} />
                            </MDBox>
                            <FieldArray
                                name='delivery_locations'
                                render={(arrayHelpers) => (
                                    <>
                                        {formik.values.delivery_locations.map((elem, index) => (
                                            <MDBox key={index} display="flex" alignItems="center" justifyContent="space-between">
                                                <MDBox sx={{ ...fieldStyle }}>
                                                    <TextField variant="outlined" sx={{ width: "100%" }} label="Delivery Location" type="text" name={`delivery_locations.${index}`} value={elem} onChange={formik.handleChange} />
                                                    {formik.errors?.delivery_locations?.[index] && formik.touched?.delivery_locations?.[index] ? (
                                                        <Box color="red" fontSize="13px">*{formik.errors?.delivery_locations?.[index]}</Box>
                                                    ) : ""}
                                                </MDBox>
                                                {formik.values?.delivery_locations?.length > 1 && <RemoveIcon color="error" fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => {
                                                    arrayHelpers.remove(index)
                                                }} />}
                                            </MDBox>
                                        ))}
                                    </>
                                )}
                            />
                        </MDBox>
                        <MDBox display="flex" justifyContent="end" pt={3}>
                            <MDButton variant="gradient" color="info" type="submit">
                                Save
                            </MDButton>
                        </MDBox>
                    </FormikProvider>
                </form>
            </Box>
        </Modal>
    )
}

export default PartyModal