import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CircularProgress, Grid, capitalize } from "@mui/material";
import UserAvatar from "assets/images/userAvatar.png";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PaymentModal from "examples/Modals/PaymentModal";
import DataTable from "examples/Tables/DataTable";
import Toaster, { showToastMessage } from "examples/Toaster";
import { useFormik } from 'formik';
import { CreatePaymentApi, GetPaymentApi, UpdatePaymentApi, deletePaymentApi } from "helper/api";
import moment from "moment";
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import ConfirmationModal from 'examples/Modals/ConfirmationModal';

const validSchema = Yup.object().shape({
    party: Yup.string().required("Party name is required"),
    amount: Yup
        .number('Only allow number').positive("Please enter positive value").integer().required("Amount is required"),
    payment_date: Yup.date().required("Date is required"),
    payment_mode: Yup.string().required("Method is required"),
    payment_type: Yup.string().required("Method type is required"),
    comment: Yup.string().trim(),
});

const Payment = () => {
    const [openModal, setOpenModal] = useState(false);
    const [userDataRow, setUserDataRow] = useState([]);
    const [userDataColumn, setUserDataColumn] = useState([]);
    const [isShowLoading, setIsShowLoading] = useState(true);
    const [modalTitle, setModalTitle] = useState("Create Payment")
    const [editPaymentData, setEditPaymentData] = useState({})
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [paymentId, setPaymentId] = useState("")

    // For Create Payments..
    const createPaymentData = async (values) => {
        const createPayment = await CreatePaymentApi(values)
        if (createPayment.status === 200) {
            showToastMessage(createPayment.message, 'success')
        } else {
            showToastMessage(createPayment.message, 'error')
        }
    }

    //For Update Payment..
    const updatePaymentData = async (values) => {
        const updatePayment = await UpdatePaymentApi(values)
        if (updatePayment.status === 200) {
            showToastMessage(updatePayment.message, 'success')
        } else {
            showToastMessage(updatePayment.message, 'error')
        }
    }

    // For Delete  Payment..
    const deletePaymentData = async () => {
        await deletePaymentApi(paymentId)
        getPaymentData();
        setOpenDeleteModal(false);
        showToastMessage("Delete Payment Successfully", 'success')
    }

    const formik = useFormik({
        initialValues: {
            party: '',
            amount: "",
            payment_date: '',
            payment_type: "",
            payment_mode: "",
            comment: "",
        },
        onSubmit: (values, { resetForm }) => {
            if (modalTitle === "Edit Payment") {
                let payload = {
                    payment_id: editPaymentData._id,
                    party: values.party,
                    amount: values.amount,
                    payment_date: values.payment_date,
                    payment_mode: values.payment_mode,
                    payment_type: values.payment_type,
                    comment: values.comment,
                }
                updatePaymentData(payload)
            } else {
                let payload = {
                    party: values.party,
                    amount: values.amount,
                    payment_date: new Date(values.payment_date),
                    payment_mode: values.payment_mode,
                    payment_type: values.payment_type,
                    comment: values.comment
                }
                createPaymentData(payload)
            }
            resetForm()
            clickHandlerClose()
            getPaymentData()
        },
        validationSchema: validSchema
    })

    const clickHandlerOpen = () => {
        setOpenModal(true);
        setModalTitle("Create Payment")
    }

    const clickHandlerClose = () => {
        setOpenModal(false);
        formik.resetForm()
        setOpenDeleteModal(false);
    };

    const editPaymentHandler = (values) => {
        setOpenModal(true);
        setModalTitle("Edit Payment")
        setEditPaymentData(values)
        formik.setValues({
            party: values.party?._id,
            amount: values.amount,
            payment_date: moment(new Date(values.payment_date)).format('YYYY-MM-DD'),
            payment_type: values.payment_type,
            payment_mode: values.payment_mode,
            comment: values.comment
        })
    }

    //For Get All Payments..
    const getPaymentData = async () => {
        const getPayment = await GetPaymentApi(pageNumber)
        setTotalPage(getPayment.totalPage)
        const Name = ({ image, name }) => (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
                <MDAvatar src={image} name={name} size="sm" />
                <MDBox ml={2} lineHeight={1}>
                    <MDTypography display="block" variant="button" fontWeight="medium">
                        {name}
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
        const column = [
            { Header: "party name", accessor: "party_name", align: "left" },
            { Header: "amount", accessor: "amount", align: "center" },
            { Header: "payment date", accessor: "payment_date", align: "center" },
            { Header: "payment method", accessor: "payment_method", align: "center" },
            { Header: "payment type", accessor: "payment_type", align: "center" },
            { Header: "comment", accessor: "comment", align: "center" },
            { Header: "action", accessor: "action", align: "center" },
        ]
        const row = getPayment.data
            ?
            getPayment.data.map((val) => {
                return {
                    party_name: <Name image={UserAvatar} name={val.party?.name} />,
                    amount: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 2,
                            }).format(val.amount)}
                        </MDTypography>
                    ),
                    payment_date: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {moment(val.payment_date).format("DD/MM/YYYY")}
                        </MDTypography>
                    ),
                    payment_method: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {capitalize(val.payment_mode)}
                        </MDTypography>
                    ),
                    payment_type: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {capitalize(val.payment_type)}
                        </MDTypography>
                    ),
                    comment: (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                            {(val.comment)}
                        </MDTypography>
                    ),
                    action: (
                        <MDTypography variant="caption" color="text" fontWeight="medium" display="flex" alignItems="center">
                            <EditIcon color="info" fontSize="medium" sx={{ cursor: "pointer" }} onClick={(() => editPaymentHandler(val))} />
                            <DeleteIcon color="error" fontSize="medium" sx={{ marginLeft: "10px", cursor: "pointer" }} onClick={(() => {
                                setOpenDeleteModal(true)
                                setPaymentId(val._id)
                            })} />
                        </MDTypography>
                    ),
                }
            })
            : []
        setUserDataColumn(column)
        setUserDataRow(row)
        setIsShowLoading(false)
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            getPaymentData()
        }
    }, [pageNumber])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pb={1} display="flex" justifyContent="end">
                <MDButton color="info" variant="gradient" onClick={clickHandlerOpen}> ADD </MDButton>
                <PaymentModal
                    handleClose={clickHandlerClose}
                    openModal={openModal}
                    title={modalTitle}
                    formik={formik}
                />
                <ConfirmationModal
                    handleClose={clickHandlerClose}
                    openModal={openDeleteModal}
                    confirmDelete={deletePaymentData}
                />
            </MDBox>
            {isShowLoading ?
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" size={20} />
                </MDBox>
                :
                <MDBox pb={2} >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox pt={1} style={{
                                    overflowY: "auto"
                                }}>
                                    <DataTable
                                        table={{ column: userDataColumn, row: userDataRow }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                        totalPage={totalPage}
                                        setPageNumber={setPageNumber}
                                        pageNumber={pageNumber}
                                        isPagination={true}
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <Toaster />
            <Footer />
        </DashboardLayout>
    )
}

export default Payment