import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import MDButton from 'components/MDButton';
import { useState } from "react";

const FilterModal = ({ filterModalClose }) => {
    const [fieldVal, setFieldVal] = useState('');
    const [materialVal, setMaterialVal] = useState('');

    const listArr = [
        {
            value: "party_name",
            label: "Party Name",
        },
        {
            value: "truck_no",
            label: "Truck No.",
        },
        {
            value: "deliver_date",
            label: "Deliver Date",
        },
        {
            value: "payment",
            label: "Payment",
        },
        {
            value: "transport_name",
            label: "Transport Name",
        },
        {
            value: "quantity",
            label: "Quantity",
        },
        {
            value: "material_type",
            label: "Material Type",
        },
    ]

    const handleChange = (event) => {
        setFieldVal(event.target.value);
    };

    const materialTypeChange = (e) => {
        setMaterialVal(e.target.value)
    }

    return (
        <Box sx={{ width: "500px" }} className="filterModal">
            <Box display="flex" justifyContent="end" >
                <CloseIcon onClick={filterModalClose} sx={{ cursor: "pointer" }} size="large" />
            </Box>
            <Box p={2}>
                <Box display="flex" justifyContent="space-between">
                    <FormControl sx={{ width: "45%" }}>
                        <InputLabel id="demo-simple-select-label" >Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fieldVal}
                            label="Filter"
                            onChange={handleChange}
                            sx={{ height: "44px" }}
                            mt={4}
                        >
                            {listArr.map((val, index) => {
                                return (
                                    <MenuItem value={val.value} key={index}>{val.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    {fieldVal &&
                        <>
                            {fieldVal !== "deliver_date" && fieldVal !== "quantity" && fieldVal !== "material_type" && <TextField id="outlined-basic" variant="outlined" label={fieldVal} sx={{ width: "45%" }} />
                            }
                            {fieldVal === "quantity" &&
                                <Box display="flex" justifyContent="space-evenly">
                                    <TextField id="outlined-basic" type="number" label="Min" variant="outlined" sx={{ width: "45%" }} />
                                    <TextField id="outlined-basic" type="number" label="Max" variant="outlined" sx={{ width: "45%" }} />
                                    <AddIcon />
                                </Box>
                            }
                            {fieldVal === "deliver_date" &&
                                <Box display="flex" justifyContent="space-evenly">
                                    <TextField id="outlined-basic" type="date" variant="outlined" sx={{ width: "45%" }} />
                                    <TextField id="outlined-basic" type="date" variant="outlined" sx={{ width: "45%" }} />
                                    <AddIcon />
                                </Box>
                            }
                            {fieldVal === "material_type" &&
                                <FormControl sx={{ width: "45%" }}>
                                    <InputLabel id="demo-simple-select-label" >Material Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={materialVal}
                                        label="material_type"
                                        onChange={materialTypeChange}
                                        sx={{ height: "44px" }}
                                        mt={4}
                                    >
                                        <MenuItem value="Abc">Abc</MenuItem>
                                    </Select>
                                    <AddIcon />
                                </FormControl>
                            }
                        </>
                    }
                </Box>
                <Box display="flex" justifyContent="end" mt={2}>
                    <Box ml={1}>
                        <MDButton variant="gradient" size="small" color="info" type="submit">
                            Search
                        </MDButton>
                    </Box>
                    <Box ml={1}>
                        <MDButton variant="gradient" size="small" color="info" type="submit">
                            Clear
                        </MDButton>
                    </Box>
                    <Box ml={1}>
                        <MDButton variant="gradient" size="small" color="info" type="submit" onClick={filterModalClose} >
                            Submit
                        </MDButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default FilterModal