import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Pagination, capitalize } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useEffect, useState } from 'react';

const Row = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ cursor: "pointer" }}
                >
                    {row.name}
                </TableCell>
                <TableCell align="center"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ cursor: "pointer" }}
                >{row.contact_number}</TableCell>
                <TableCell align="center"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ cursor: "pointer" }}
                >{row.contact_person_name}</TableCell>
                <TableCell align="center"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ cursor: "pointer" }}
                >{row.opening_balance}</TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="right">{row.action}</TableCell>
                <TableCell align="right">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12} >
                    <Collapse in={open} timeout="auto" style={{ width: "100%" }} >
                        <Box sx={{ margin: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Delivery Locations :
                            </Typography>
                            <TableContainer style={{ marginTop: "20px", marginBottom: "20px" }} >
                                <Table aria-label="simple table">
                                    <TableHead style={{ display: "table-header-group" }}>
                                        <TableRow >
                                            <TableCell style={{ fontWeight: 700, fontSize: "0.65rem", }}>
                                                No
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 700, fontSize: "0.65rem" }}>
                                                Delivery Locations
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.delivery_locations?.map((val, index) => (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                                                <TableCell component="th" scope="row">
                                                    <MDTypography variant="caption" color="text" fontWeight="medium">
                                                        {index + 1}
                                                    </MDTypography>
                                                </TableCell>
                                                <TableCell>
                                                    <MDTypography variant="caption" color="text" fontWeight="medium">{capitalize(val)}</MDTypography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow >
        </>
    );
}

const CollapsibleDataTable = ({ table, totalPage, setPageNumber, pageNumber }) => {

    const handleChange = (e, p) => {
        setPageNumber(p)
    }

    useEffect(() => {
        if (table.row.length === 0 && pageNumber > 1) {
            const newPageNumber = Math.max(1, pageNumber - 1)
            setPageNumber(newPageNumber);
        }
    }, [table.row.length]);

    return (
        <TableContainer component={Paper} style={{ width: "100%", height: "70vh", overflow: 'auto' }}>
            <Table aria-label="simple table">
                <TableHead style={{ display: "table-header-group" }}>
                    <TableRow>
                        {table.column.map((val, index) => (
                            <TableCell key={index} align={val.align} style={{ fontWeight: 700, fontSize: "0.65rem", paddingBottom: "0.60rem", paddingTop: "0.60rem", paddingLeft: "0.50rem", paddingRight: "0.50rem" }} >
                                {val?.Header?.toUpperCase()}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {table.row.length > 0
                    ?
                    <TableBody>
                        {table.row.map((val, index) => (
                            <Row key={index} row={val} />
                        ))}
                        {totalPage > 1 && <MDBox style={{ height: 50 }} />}
                    </TableBody>
                    :
                    <TableBody style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
                        <TableRow >
                            No data found
                        </TableRow>
                    </TableBody>
                }
                {totalPage > 1 &&
                    <MDBox style={{
                        display: "flex", justifyContent: "center", alignItems: "center",
                        position: "absolute", width: "100%", backgroundColor: "white", bottom: 0,
                        // borderBottomRightRadius:15, borderBottomLeftRadius:15
                    }}>
                        <Box py={1}>
                            <Pagination
                                count={totalPage}
                                size="medium"
                                page={pageNumber}
                                color="info"
                                onChange={handleChange}
                            />
                        </Box>
                    </MDBox>
                }
            </Table>
        </TableContainer>
    );
}


export default CollapsibleDataTable