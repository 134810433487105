import { useEffect, useMemo, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';
import { ThemeProvider } from '@mui/material/styles';
import MDBox from 'components/MDBox';
import Configurator from 'examples/Configurator';
import Sidenav from 'examples/Sidenav';
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
import routes from 'routes';
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from 'helper/context';
import brandDark from 'assets/images/logo-ct-dark.png';
import brandWhite from 'assets/images/logo-ct.png';
import Profile from 'layouts/profile';

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin]
    });
    setRtlCache(cacheRtl);
  }, []);

  useEffect(() => {
    if (localStorage.getItem('accessToken') !== null) {
      setTimeout(() => {
        setIsLoading(false)
      }, 2000);
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 2000);
      navigate('/authentication/sign-in');
    }
  }, [pathname]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2px"
      bottom="4px"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}>
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return isLoading ? (
    <div className="loader">
      <p>Aavkar Corporation</p>
    </div>
  ) :
    direction === 'rtl' ? (
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline />
          {layout === 'dashboard' && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="Aavkar Corporation"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* <Configurator /> */}
              {/* {configsButton} */}
            </>
          )}
          {layout === 'vr' && <Configurator />}
          <Routes>
            {getRoutes([
              ...routes,
              {
                type: 'collapse',
                name: 'Profile',
                key: 'profile',
                icon: <Icon fontSize="small">account_circle</Icon>,
                route: '/profile',
                component: <Profile />
              }
            ])}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </ThemeProvider>
      </CacheProvider>
    ) : (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === 'dashboard' && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Aavkar Corporation"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === 'vr' && <Configurator />}
        <Routes>
          {getRoutes([
            ...routes,
            {
              type: 'collapse',
              name: 'Profile',
              key: 'profile',
              icon: <Icon fontSize="small">account_circle</Icon>,
              route: '/profile',
              component: <Profile />
            }
          ])}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    );
}

export default App
