import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from 'helper/context';
import AppContextProvider from 'helper/context/AppContextProvider';

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <AppContextProvider>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </AppContextProvider>
  </BrowserRouter>
);
