import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { FormControl } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import { FieldArray, FormikProvider } from 'formik';
import { GetPartyApi } from 'helper/api';
import { useEffect, useState } from 'react';

const OrderModal = ({ openModal, title, formik, handleClose }) => {
    const [allPartyData, setAllPartyData] = useState([])
    const [partyFilterData, setPartyFilterData] = useState()

    const fieldStyle = {
        width: "100%",
        my: 1,
    }

    //for get Party Data..
    const partyData = async () => {
        const getParty = await GetPartyApi()
        setAllPartyData(getParty.data)
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            partyData()
        }
    }, [])

    useEffect(() => {
        const partyFilter = allPartyData.find((elem) => {
            return (
                formik?.values?.partyName && elem._id === formik.values?.partyName)
        })
        setPartyFilterData(partyFilter)
    }, [formik?.values?.partyName])

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box className="modal " width={{ md: 750 }}>
                <MDBox display="flex" alignItems="center" justifyContent="space-between" pb={1}>
                    <h3>{title}</h3>
                    <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
                </MDBox>
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <MDBox className="filedGrid">
                            {(title === "Create Outgoing Order" || title === "Edit Outgoing Order")
                                &&
                                <MDBox my={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Party Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={formik.values.partyType}
                                            label="Party Type"
                                            onChange={formik.handleChange}
                                            fullWidth
                                            disabled={title === "Edit Outgoing Order"}
                                            sx={{ height: "44px" }}
                                            mt={4}
                                            name='partyType'
                                        >
                                            <MenuItem value="retail">Retail</MenuItem>
                                            <MenuItem value="regular">Regular</MenuItem>
                                        </Select>
                                        {formik.errors.partyType && formik.touched.partyType ? (
                                            <Box color="red" fontSize="13px">*{formik.errors.partyType}</Box>
                                        ) : <p></p>}
                                    </FormControl>
                                </MDBox>
                            }
                            {(title === "Create Outgoing Order" || title === "Edit Outgoing Order") && (formik.values.partyType && formik.values.partyType === "retail")
                                ?
                                <MDBox>
                                    <TextField variant="outlined" sx={{ ...fieldStyle }} label="Party Name" type="text" name="regularPartyName" value={formik.values.regularPartyName} onChange={formik.handleChange} autoComplete='off' />
                                    <p style={{ color: "red", fontSize: "13px" }}> {formik.errors.regularPartyName && formik.touched.regularPartyName ? (
                                        <Box color="red" fontSize="13px">*{formik.errors.regularPartyName}</Box>
                                    ) : ""}</p>
                                </MDBox>
                                :
                                <MDBox mt={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Party Name</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={formik.values.partyName}
                                            label="Party Name"
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ height: "44px" }}
                                            mt={4}
                                            name='partyName'
                                        >
                                            {allPartyData.map((val, index) => {
                                                return (
                                                    <MenuItem value={val._id} key={index}>{val.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                        {formik.errors.partyName && formik.touched.partyName ? (
                                            <Box color="red" fontSize="13px">*{formik.errors.partyName}</Box>
                                        ) : <p></p>}
                                    </FormControl>
                                </MDBox>
                            }
                            {(title === "Create Outgoing Order" || title === "Edit Outgoing Order")
                                && formik.values.partyType === "regular" && formik.values.partyName &&
                                <MDBox mt={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Delivery Location</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={formik.values.delivery_location}
                                            label="Delivery Location"
                                            onChange={formik.handleChange}
                                            fullWidth
                                            sx={{ height: "44px" }}
                                            mt={4}
                                            name='delivery_location'
                                        >
                                            {partyFilterData?.delivery_locations.map((val, index) => {
                                                return (
                                                    <MenuItem value={val} key={index}>{val}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                        {formik.errors.delivery_location && formik.touched.delivery_location ? (
                                            <Box color="red" fontSize="13px">*{formik.errors.delivery_location}</Box>
                                        ) : <p></p>}
                                    </FormControl>
                                </MDBox>
                            }
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} type="date" name="deliveryDate" value={formik.values.deliveryDate} onChange={formik.handleChange} />
                                <p style={{ color: "red", fontSize: "13px" }}> {formik.errors.deliveryDate && formik.touched.deliveryDate ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.deliveryDate}</Box>
                                ) : ""}</p>
                            </MDBox>
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} label="Truck Number" type="text" name="truckNumber" value={formik.values.truckNumber} onChange={formik.handleChange} />
                                {formik.errors.truckNumber && formik.touched.truckNumber ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.truckNumber}</Box>
                                ) : null}
                            </MDBox>
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} label="Transport Name" type="text" name="transportName" value={formik.values.transportName} onChange={formik.handleChange} />
                                {formik.errors.transportName && formik.touched.transportName ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.transportName}</Box>
                                ) : null}
                            </MDBox>
                            <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} label="Invoice Number" type="text" name="invoiceNumber" value={formik.values.invoiceNumber} onChange={formik.handleChange} />
                                {formik.errors.invoiceNumber && formik.touched.invoiceNumber ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.invoiceNumber}</Box>
                                ) : null}
                            </MDBox>
                            {(title === "Create Outgoing Order" || title === "Edit Outgoing Order") && <MDBox>
                                <TextField variant="outlined" sx={{ ...fieldStyle }} label="Comment" type="text" name="comment" value={formik.values.comment} onChange={formik.handleChange} />
                                {formik.errors.comment && formik.touched.comment ? (
                                    <Box color="red" fontSize="13px">*{formik.errors.comment}</Box>
                                ) : null}
                            </MDBox>}
                        </MDBox>
                        <MDBox mt={2} >
                            <MDBox display="flex" alignItems="center" justifyContent="space-between" my={1}>
                                <MDBox fontSize={20}>Materials :</MDBox>
                                <AddIcon color="info" fontSize="medium" sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                        const materialsClone = [...formik.values.materials]
                                        materialsClone.push({
                                            material_type: "",
                                            qty_in_ton: "",
                                            weight: "",
                                            rate: "",
                                            amount: "",
                                        })
                                        formik.setFieldValue('materials', materialsClone)
                                    }} />
                            </MDBox>
                            <FieldArray
                                name='materials'
                                render={(arrayHelpers) => (
                                    <div>
                                        {formik.values.materials.map((elem, index) => (
                                            <MDBox className="materialFiled" key={index} mt={1} display="flex" alignItems="start">
                                                <Box sx={{ minWidth: "20%", maxWidth: "25%" }} className="materialTypeField">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Material Type</InputLabel>
                                                        <Select
                                                            name={`materials.${index}.material_type`}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={formik.values.materials?.[index]?.material_type}
                                                            label="Material Type"
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`materials.${index}.qty_in_ton`, "");
                                                                formik.setFieldValue(`materials.${index}.weight`, "");
                                                                formik.setFieldValue(`materials.${index}.amount`, "");
                                                                formik.setFieldValue(`materials.${index}.rate`, "");
                                                                formik.handleChange(e)
                                                            }}
                                                            sx={{ height: "44px" }}
                                                        >
                                                            <MenuItem value="cement">Cement</MenuItem>
                                                            <MenuItem value="steel">Steel</MenuItem>
                                                            <MenuItem value="wire">Wire</MenuItem>
                                                        </Select>
                                                        {formik.errors.materials?.[index]?.material_type && formik.touched?.materials?.[index]?.material_type ? (
                                                            <Box color="red" fontSize="13px">*{formik.errors?.materials?.[index]?.material_type}</Box>
                                                        ) : ""}
                                                    </FormControl>
                                                </Box>
                                                <MDBox>
                                                    <TextField variant="outlined" label="Quantity (In Ton)" type="number" name={`materials.${index}.qty_in_ton`}
                                                        value={elem.qty_in_ton}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                            let weightCalculate = formik.values.materials?.[index]?.material_type === "cement" ? e.target.value * 20 : e.target.value * 1000;
                                                            if (["steel", "wire"].includes(formik.values.materials?.[index]?.material_type) && !e.target.value.length) {
                                                                formik.setFieldValue(`materials.${index}.weight`, "");
                                                            } else {
                                                                formik.setFieldValue(`materials.${index}.weight`, weightCalculate ?? null);
                                                            }
                                                            let amountCalculate = 0
                                                            if (formik.values.materials?.[index]?.material_type === "cement") {
                                                                amountCalculate = weightCalculate * elem.rate
                                                            } else {
                                                                amountCalculate = e.target.value * elem.rate
                                                            }
                                                            if (!["steel", "wire"].includes(formik.values.materials?.[index]?.material_type)) {
                                                                formik.setFieldValue(`materials.${index}.amount`, amountCalculate);
                                                            }
                                                        }}
                                                    />
                                                    {formik.errors?.materials?.[index]?.qty_in_ton && formik.touched?.materials?.[index]?.qty_in_ton ? (
                                                        <Box color="red" fontSize="13px">*{formik.errors?.materials?.[index]?.qty_in_ton}</Box>
                                                    ) : ""}
                                                </MDBox>
                                                <MDBox>
                                                    <TextField
                                                        variant="outlined"
                                                        type="number"
                                                        label={formik.values.materials?.[index]?.material_type !== "cement" ? "Kgs" : "Bags"}
                                                        name={`materials.${index}.weight`}
                                                        value={elem.weight}
                                                        onChange={(e) => {
                                                            formik.handleChange(e)
                                                            let quantityCalculate = formik.values.materials?.[index]?.material_type === "cement" ? e.target.value / 20 : e.target.value / 1000;
                                                            if (["steel", "wire"].includes(formik.values.materials?.[index]?.material_type) && !e.target.value.length) {
                                                                formik.setFieldValue(`materials.${index}.qty_in_ton`, "");
                                                            } else {
                                                                formik.setFieldValue(`materials.${index}.qty_in_ton`, quantityCalculate ?? null);
                                                            }
                                                            let amountCalculate = 0
                                                            if (formik.values.materials?.[index]?.material_type === "cement") {
                                                                amountCalculate = e.target.value * elem.rate
                                                            } else {
                                                                amountCalculate = quantityCalculate * elem.rate
                                                            }
                                                            if (!["steel", "wire"].includes(formik.values.materials?.[index]?.material_type)) {
                                                                formik.setFieldValue(`materials.${index}.amount`, amountCalculate);
                                                            }
                                                        }} />
                                                    {formik.errors?.materials?.[index]?.weight && formik.touched?.materials?.[index]?.weight ? (
                                                        <Box color="red" fontSize="13px">*{formik.errors?.materials?.[index]?.weight}</Box>
                                                    ) : ""}
                                                </MDBox>
                                                <MDBox>
                                                    <TextField variant="outlined"
                                                        type="number"
                                                        name={`materials.${index}.rate`}
                                                        label={formik.values.materials?.[index]?.material_type !== "cement" ? "Rate(Per Ton)" : "Rate(Per Bag"}
                                                        value={elem.rate}
                                                        onChange={(e) => {
                                                            formik.handleChange(e)
                                                            let amountCalculate = 0
                                                            if (formik.values.materials?.[index]?.material_type === "cement") {
                                                                amountCalculate = formik.values.materials?.[index]?.weight * e.target.value
                                                            } else {
                                                                amountCalculate = formik.values.materials?.[index]?.qty_in_ton * e.target.value
                                                            }
                                                            if (!["steel", "wire"].includes(formik.values.materials?.[index]?.material_type)) {
                                                                formik.setFieldValue(`materials.${index}.amount`, amountCalculate);
                                                            }
                                                        }}
                                                    />
                                                    {formik.errors?.materials?.[index]?.rate && formik.touched?.materials?.[index]?.rate ? (
                                                        <Box color="red" fontSize="13px">*{formik.errors?.materials?.[index]?.rate}</Box>
                                                    ) : ""}
                                                </MDBox>
                                                <MDBox>
                                                    <TextField variant="outlined" label="Total" type="number" name={`materials.${index}.amount`}
                                                        value={elem.amount}
                                                        onChange={formik.handleChange}
                                                        disabled={!["steel", "wire"].includes(formik.values.materials?.[index]?.material_type)} />
                                                    {formik.errors?.materials?.[index]?.amount && formik.touched?.materials?.[index]?.amount ? (
                                                        <Box color="red" fontSize="13px">*{formik.errors?.materials?.[index]?.amount}</Box>
                                                    ) : ""}
                                                </MDBox>
                                                {formik.values?.materials?.length > 1 && <RemoveIcon color="error" fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => {
                                                    arrayHelpers.remove(index)
                                                }} />}
                                            </MDBox>
                                        ))}
                                    </div>
                                )}
                            />
                        </MDBox>
                        <MDBox display="flex" justifyContent="end" pt={3}>
                            <MDButton variant="gradient" color="info" type="submit">
                                Save
                            </MDButton>
                        </MDBox>
                    </FormikProvider>
                </form>
            </Box>
        </Modal >
    )
}

export default OrderModal