import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Card, CircularProgress, Grid, Menu, Switch, capitalize } from "@mui/material";
import UserAvatar from "assets/images/userAvatar.png";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FilterModal from "examples/Modals/FilterModal";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Toaster, { showToastMessage } from "examples/Toaster";
import { useFormik } from 'formik';
import { CreateUserApi, GetUserApi, UpdateUserApi } from "helper/api";
import { useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import UserModal from "../../examples/Modals/UserModal";
import ConfirmationModal from 'examples/Modals/ConfirmationModal';

const Users = () => {
    const [openModal, setOpenModal] = useState(false);
    const [userDataRow, setUserDataRow] = useState([]);
    const [userDataColumn, setUserDataColumn] = useState([]);
    const [isShowLoading, setIsShowLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalTitle, setModalTitle] = useState("")
    const [editUserData, setEditUserData] = useState({})
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const open = Boolean(anchorEl);

    //For Create User..
    const createUserData = async (values) => {
        const createUser = await CreateUserApi(values)
        if (createUser.status === 200) {
            let { user } = createUser
            localStorage.setItem('user', JSON.stringify(user));
            showToastMessage(createUser.message, 'success')
        } else {
            showToastMessage(createUser.message, 'error')
        }
    }

    //For Update User..
    const updateUserData = async (values) => {
        const updateUser = await UpdateUserApi(values)
        if (updateUser.status === 200) {
            showToastMessage(updateUser.message, 'success')
            getUserData();
        } else {
            showToastMessage(updateUser.message, 'error')
        }
    }

    //For Update User Activity..
    const userActiveHandler = () => {
        const payload = {
            user_id: editUserData._id,
            is_active: !editUserData.is_active,
        }
        updateUserData(payload)
        setOpenDeleteModal(false)
    }

    const clickHandlerOpen = () => {
        setModalTitle("Create User")
        setOpenModal(true);
    }

    const editUserHandler = async (values) => {
        setOpenModal(true);
        setIsEdit(true)
        setModalTitle("Edit User")
        setEditUserData(values)
        formik.setValues({
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            role: values.role,
        })
    }

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().trim().required("First name is required"),
        last_name: Yup.string().trim().required("Last name is required"),
        email: Yup.string().trim().email("Invalid email address format").required("Email is required"),
        password: !isEdit && Yup.string()
            .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,20}$/, "Must be contain at least 1 lower case, 1 upper case, 1 digit and 1 special character")
            .required("Password is required"),
        role: Yup.string().required("Role is required"),
    });

    const clickHandlerClose = () => {
        formik.resetForm()
        setIsEdit(false)
        setOpenModal(false);
        setOpenDeleteModal(false)
    };

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            role: '',
        },
        onSubmit: (values, { resetForm }) => {
            if (modalTitle === "Edit User") {
                const payload = {
                    user_id: editUserData._id,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    is_active: editUserData.is_active,
                }
                updateUserData(payload)
            } else {
                createUserData(values)
            }
            resetForm()
            clickHandlerClose()
            getUserData()
        },
        validationSchema: validationSchema,
    })

    //For Get All User..
    const getUserData = async () => {
        const getUsers = await GetUserApi(pageNumber)
        setTotalPage(getUsers.totalPage)
        if (getUsers.data) {
            const Name = ({ image, fname, lname }) => (
                <MDBox display="flex" alignItems="center" lineHeight={1} sx={{ cursor: "pointer" }}>
                    <MDAvatar src={image} name={fname} size="sm" />
                    <MDBox ml={2} lineHeight={1}>
                        <MDTypography display="block" variant="button" fontWeight="medium">
                            {fname} {lname}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            );

            const column = [
                { Header: "name", accessor: "name", align: "left" },
                { Header: "email", accessor: "email", align: "left" },
                { Header: "status", accessor: "status", align: "center" },
                { Header: "role", accessor: "role", align: "center" },
                { Header: "action", accessor: "action", align: "center" },
            ]

            const row = getUsers.data
                ?
                getUsers.data.map((val) => {
                    return {
                        name: <Name image={UserAvatar} fname={val.first_name} lname={val.last_name} />,
                        email: (
                            <MDTypography variant="caption" color="text" fontWeight="medium">
                                {val.email}
                            </MDTypography>
                        ),
                        status: (
                            <MDBox ml={-1}>
                                <Switch checked={val.is_active} onChange={() => {
                                    setOpenDeleteModal(true)
                                    setEditUserData(val)
                                }} />
                            </MDBox>
                        ),
                        role: (
                            <MDTypography variant="caption" color="text" fontWeight="medium">
                                {capitalize(val.role)}
                            </MDTypography>
                        ),
                        action: (
                            <MDTypography variant="caption" color="text" fontWeight="medium" display="flex" alignItems="center">
                                <EditIcon color="info" fontSize="medium" sx={{ cursor: "pointer" }} onClick={(() => editUserHandler(val))} />
                                {/* <DeleteIcon color="error" fontSize="medium" onClick={(() => deleteOrderHandler(val._id))} /> */}
                            </MDTypography>
                        ),
                    }
                })
                : []
            setUserDataColumn(column)
            setUserDataRow(row)
            setIsShowLoading(false)
        }
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            getUserData()
        }
    }, [pageNumber])

    const filterModalOpen = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const filterModalClose = () => {
        setAnchorEl(null);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pb={1} display="flex" justifyContent="end">
                {/* <MDButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={filterModalOpen}
                    color="info"
                    variant="gradient"
                    sx={{ marginRight: 1 }}
                >
                    <FilterAltIcon />
                </MDButton> */}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={filterModalClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}>
                    <FilterModal filterModalClose={filterModalClose} />
                </Menu>
                <MDButton color="info" variant="gradient" onClick={clickHandlerOpen} > ADD </MDButton>
                <UserModal
                    formik={formik}
                    handleClose={clickHandlerClose}
                    openModal={openModal}
                    title={modalTitle}
                    isEdit={isEdit}
                />
            </MDBox>
            <ConfirmationModal
                handleClose={clickHandlerClose}
                openModal={openDeleteModal}
                confirmDelete={userActiveHandler}
                type="updateUser"
            />
            {isShowLoading ?
                <MDBox display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" size={20} />
                </MDBox>
                :
                <MDBox pb={2} >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox pt={1} style={{
                                    overflowY: "auto"
                                }}>
                                    <DataTable
                                        table={{ column: userDataColumn, row: userDataRow }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                        totalPage={totalPage}
                                        setPageNumber={setPageNumber}
                                        pageNumber={pageNumber}
                                        isPagination={true}
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <Toaster />
            <Footer />
        </DashboardLayout>
    )
}

export default Users