import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showToastMessage = (message, type) => {
    toast[type](message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
    });
}

const Toaster = () => {
    return (
        <div>
            <ToastContainer style={{ fontSize: "14px" }} />
        </div>
    );
}

export default Toaster