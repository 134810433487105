import { Button, Card, CircularProgress, Grid } from "@mui/material";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Toaster from "examples/Toaster";
import { GetOutstandingListApi } from "helper/api";
import PHE from "print-html-element";
import { useEffect, useRef, useState } from "react";

const OutstandingList = () => {
    const [outstandingListRow, setOutstandingListRow] = useState([]);
    const [outstandingListColumn, setOutstandingListColumn] = useState([]);
    const [isShowLoading, setIsShowLoading] = useState(true);
    const [value, onChange] = useState([])
    const tableRef = useRef(null);

    //For Get All Outstanding List..
    const getOutstandingListData = async (payload) => {
        const getOutstandingList = await GetOutstandingListApi(payload)
        const column = [
            { Header: "Party Name", accessor: "partyName", align: "center" },
            { Header: "Contact Person Name", accessor: "contactPersonName", align: "center" },
            { Header: "Contact Number", accessor: "contactNumber", align: "center" },
            { Header: "Amount", accessor: "amount", align: "center" },
        ]
        const row = getOutstandingList.data
            ?
            getOutstandingList.data.map((val) => {
                return {
                    partyName: val.name,
                    contactPersonName: val.contact_person_name,
                    contactNumber: val.contact_number,
                    amount: new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 2,
                    }).format(val.outstanding_amount)
                }
            })
            :
            []
        setOutstandingListColumn(column)
        setOutstandingListRow(row)
        setIsShowLoading(false)
    }

    function getPdfTableCells(data, type) {
        return data.map(cell => `<${type}>${cell.Header}</${type}>`).join('');
    }

    const createPdfTableBody = (data) => {
        return data.map((row, rowIndex) => {
            const cells = Object.values(row).map((cell, colIndex) => `<td key=${colIndex}>${cell}</td>`).join('');
            return `<tr key=${rowIndex}>${cells}</tr>`;
        }).join('');
    }

    let htmlString = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <style>
        body{
            width:100% !important;
        }
            table {
                border-collapse: collapse;
                width:100% !important
            }
            th {
                padding: 7px 6px;
                text-align: center;
                border-bottom: 1px solid black;
                font-size: 14px
            }
            td,tr {
                padding: 7px 6px;
                text-align: center;
                font-size: 14px
            }
            .pdf-container {
                width: 100%;
                display: flex; 
            }
            .pdf-table {
                margin: 3px; 
                width: 100%;          
            }
        </style>
    </head> 
        <body>
            <div class="pdf-container">
                <div class="pdf-table">
                    <table>
                        <thead>${getPdfTableCells((outstandingListColumn), 'th')}</thead>
                        <tbody>${createPdfTableBody((outstandingListRow))}</tbody>
                    </table>
                </div>
            </div>  
        </body>
    </html>
  `

    const printPdfHandler = async () => {
        // PHE.printHtml(htmlString)

        // const printWindow = window.open('', '_blank');
        // printWindow.document.write(htmlString);
        // printWindow.document.close();
        // setTimeout(() => {
        //     printWindow.print(htmlString);
        //     printWindow.close();
        // }, 1000);

        // Create a hidden iframe to hold the printable content
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.contentDocument.write(htmlString);
        iframe.contentDocument.close();
        iframe.contentWindow.print();

        // Remove the iframe from the DOM after printing
        setTimeout(() => {
            document.body.removeChild(iframe);
        }, 1000);
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null) {
            const payload = {
                filters: {
                    date: value ?? []
                }
            }
            getOutstandingListData(payload)
        }
    }, [value])

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <MDBox className="outstandingSection">
                <MDBox className="">
                    <DateRangePicker
                        onChange={onChange}
                        value={value}
                        format="d/M/y"
                        monthPlaceholder="mm"
                        dayPlaceholder="d"
                        yearPlaceholder="yyyy"
                        autoFocus={false}
                    />
                </MDBox>
                <MDBox className="listPrintBtn">
                    <MDButton color="info" variant="gradient" onClick={printPdfHandler} className="btn">Print</MDButton>
                </MDBox>
            </MDBox>

            {isShowLoading ?
                <CircularProgress color="white" size={20} />
                :
                <MDBox pb={2} >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox pt={1} style={{
                                    overflowY: "auto"
                                }}>
                                    <DataTable
                                        tableRef={tableRef}
                                        table={{ column: outstandingListColumn, row: outstandingListRow }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                        id="outstandingList"
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            }
            <Toaster />
            <Footer />
        </DashboardLayout>
    )
}
export default OutstandingList;
