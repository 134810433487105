import CloseIcon from '@mui/icons-material/Close';
import { Box } from "@mui/material";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Toaster, { showToastMessage } from "examples/Toaster";
import { useFormik } from 'formik';
import { ChangePasswordApi } from "helper/api";
import { useState } from "react";
import * as Yup from 'yup';

const validSchema = Yup.object().shape({
    newPassword: Yup.string()
        .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,20}$/, "Must be contain at least 1 lower case, 1 upper case, 1 digit and 1 special character")
        .required("Password is required"),
    conformPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords not match')
        .required("Passwords is required"),
});

const Profile = () => {
    const [openModal, setOpenModal] = useState(false);

    const fieldStyle = {
        width: "100%",
        my: 1,
    }
    const handleClose = () => setOpenModal(false)

    //For Create User..
    const changeUserPassword = async (values) => {
        const changePassword = await ChangePasswordApi(values)
        if (changePassword.status === 200) {
            showToastMessage(changePassword.message, 'success')
        } else {
            showToastMessage(changePassword.message, 'error')
        }
    }

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            conformPassword: '',
        },
        onSubmit: (values, { resetForm }) => {
            const passwordData = {
                user_id: JSON.parse(localStorage.getItem("user"))._id,
                password: values.newPassword
            }
            changeUserPassword(passwordData)
            resetForm()
            handleClose()
        },
        validationSchema: validSchema
    })

    const FIRST_NAME = JSON.parse(localStorage.getItem("user")).first_name
    const LAST_NAME = JSON.parse(localStorage.getItem("user")).last_name
    const EMAIL = JSON.parse(localStorage.getItem("user")).email

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={4} mt={2} backgroundColor="#ffffff" sx={{ boxShadow: 3, borderRadius: "10px", mx: "auto" }}>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ borderRadius: "50%", backgroundColor: "lightgray", color: "gray", height: "150px", width: "150px" }}>
                        <h1>{FIRST_NAME.charAt(0).toUpperCase()}{LAST_NAME.charAt(0).toUpperCase()}</h1>
                        {/* <img src={UserAvatar} alt="UserAvatar" style={{ width: "150px" }} /> */}
                    </Box>
                    <Box style={{ alignItems: "start" }}>
                        <Box mt={2} display="flex" alignItems="center" justifyContent="left" sx={{ fontSize: "16px" }}>
                            <label style={{ fontWeight: 900 }}>Name: </label>
                            <p>&nbsp;{FIRST_NAME} {" "}
                                {LAST_NAME} </p>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="left" sx={{ fontSize: "16px" }}>
                            <label style={{ fontWeight: 900 }}>Email: </label>
                            <p>&nbsp;{EMAIL}</p>
                        </Box>
                    </Box>
                </Box>
                <Box mt={2}>
                    <MDButton variant="gradient" color="info" type="submit" onClick={() => setOpenModal(true)}>
                        Change Password
                    </MDButton>
                </Box>
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box className="modal">
                        <MDBox display="flex" alignItems="center" justifyContent="space-between" pb={1}>
                            <h3>Change Password </h3>
                            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
                        </MDBox>
                        <form onSubmit={formik.handleSubmit}>
                            <Box className="filedGrid">
                                <MDBox >
                                    <TextField variant="outlined" sx={{ ...fieldStyle }} label="New Password" type="password" name="newPassword" value={formik.values.newPassword} onChange={formik.handleChange} />
                                    {formik.errors.newPassword && formik.touched.newPassword ? (
                                        <Box color="red" fontSize="13px">*{formik.errors.newPassword}</Box>
                                    ) : null}
                                </MDBox>
                                <MDBox >
                                    <TextField variant="outlined" sx={{ ...fieldStyle }} label="Conform Password" type="password" name="conformPassword" value={formik.values.conformPassword} onChange={formik.handleChange} />
                                    {formik.errors.conformPassword && formik.touched.conformPassword ? (
                                        <Box color="red" fontSize="13px">*{formik.errors.conformPassword}</Box>
                                    ) : null}
                                </MDBox>
                            </Box>
                            <MDBox display="flex" justifyContent="end" pt={3}>
                                <MDButton variant="gradient" color="info" type="submit">
                                    Save
                                </MDButton>
                            </MDBox>
                        </form>
                    </Box>
                </Modal>
            </MDBox>
            <Footer />
            <Toaster />
        </DashboardLayout >
    )
}

export default Profile