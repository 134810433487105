import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const API = axios.create({
  baseURL: BASE_URL
});

API.interceptors.request.use((config) => {
  if (localStorage.getItem('accessToken')) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }
  return config;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.data?.error && error?.response?.data?.message) {
      throw {
        error: true,
        message: error?.response?.data?.message
      };
    }
    throw error;
  }
);
export { API };
